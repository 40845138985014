.page-news {

  .news-summary {
    margin-bottom: $unit;
    .news-title {
      a {
        @include fs(3);
        color: black;
        text-decoration: none;
        transition: all .4s;
        &:hover {
          color: #00a8ff;
          text-decoration: underline;
        }
      }
    }
    .desc {
      color: #333;
      @include fs(1);
    }
    .ymd {
      padding-top: 8px;
      .label {
        padding: 4px 8px;
        color: white;
        font-weight: bold;
        background: #7f8fa6;
      }
    }
  }

  header {
    padding: $unit;
    .label {
      padding: 4px $unit;
      background: #27ae60;
      color: white;
      font-weight: bold;
      margin-right: $unit/2;
      display: inline-block;
    }

    .date {
      font-weight: bold;
      display: inline-block;
    }

    h1 {
      margin-top: $unit/2;
      @include fs(3);
      font-weight: bold;
    }
  }

  .body {
    padding: $unit;

    .timeline-message {
      font-weight: bold;
      margin-bottom: $unit;
    }
  }


}