/****************************************************************************
 * ヘッダ
 ****************************************************************************/
.l-header {
  width: 100%;
  padding: $unit 0;
  .outline {
    display: flex;
    width: 960px;
    margin: 0 auto;
    align-items: center;

    a.brand {
      flex-basis: 50%;
      text-decoration: none;
      color: #555;
      transition: opacity .4s;
      &:hover {
        opacity: .8;
      }
      .app-name {
        @include fs(2);
        //display: inline-block;
        font-weight: bold;
        width: 240px;
        svg {
          vertical-align: bottom;
        }
      }

      .desc {
        @include fs(-2);
        padding-left: 8px;
        //display: inline-block;
      }
    }

    .nav-user-pages {
      flex-basis: 50%;
      text-align: right;

      a {
        display: inline-block;
        padding: $unit/2 $unit*2;
        color: white;
        font-weight: bold;
        text-decoration: none;
      }

      .btn-register {
        background: #f7b731;
      }

      .btn-login {
        background: #eb3b5a;
      }
    }
  }
}

/****************************************************************************
 * グローバルナビ
 ****************************************************************************/
.nav-global {
  border-bottom: 1px solid whitesmoke;

  .outline {
    width: 960px;
    margin: 0 auto;
    display: flex;
  }
  a {
    text-decoration: none;
    display: inline-block;
  }

  .nav-commu {
    align-self: flex-end;
    flex-basis: 55%;
    a {
      padding: $unit/2 $unit;
      font-weight: bold;
      color: black;
      transition: color .4s;
      &:hover {
        color: #4b7bec;
      }
    }
  }
  .nav-recruit {
    text-align: right;
    flex-basis: 45%;
    a {
      color: white;
      padding: $unit/2 $unit;
      text-align: center;
      &.item-chugaku {
        background: #0fb9b1;
      }

      &.item-koukou {
        background: #fa8231;
      }

      &.item-daigaku {
        background: #4b7bec;
      }
    }
    span {
      &.prefix, &.main-text, &.suffix {
        display: block;
        @include fs(-1);
      }

      &.main-text {
        @include fs(1);
        font-weight: bold;
      }
    }
  }
}
/****************************************************************************
 * メイン
 ****************************************************************************/
.l-contents {
  padding: $unit*2 0;
  > .outline {
    display: flex;
    width: 960px;
    margin: 0 auto;
    main {
      flex-basis: 72%;
    }
    .nav-sidebar {
      flex-basis: 28%;
    }
  }
}
/****************************************************************************
 * パンくず
 ****************************************************************************/
.nav-topic-path {
  border-bottom: 1px solid whitesmoke;
  .outline {
    width: 960px;
    margin: 0 auto;
  }
  @include fs(-2);
  padding: $unit/2 $unit;
  background: white;
  .separate {
    display: inline-block;
    padding: 0 $unit/2;
  }
  a {
    text-decoration: none;
    color: black;
    transition: all .4s;
    &:hover {
      color: #4b7bec;
    }
  }
}

/****************************************************************************
 * サイドバー
 ****************************************************************************/
.nav-sidebar {
  padding-left: $unit;
  h1, p, dt, dd, a {
    @include fs(-1);
  }
  a {
    text-decoration: none;
    color: black;
  }

  a.btn-first-guide {
    display: block;
    margin-bottom: $unit;
    background: #4b7bec;
    color: white;
    font-weight: bold;
    padding: $unit*2 $unit;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color .4s;
    span {
      display: block;
      &.prefix {
        @include fs(2);
      }
    }
    &:hover {
      background: #fa8231;
    }
  }

  .section {
    border: 1px solid whitesmoke;
    margin-bottom: $unit;
    header {
      padding: $unit;
      border-bottom: 1px solid whiteSmoke;
      h1 {
        font-weight: bold;
      }
    }

    .body {
      padding: $unit;
      dl {
        &.default {
          dt {
            font-weight: bold;
          }
          dd {
            padding-left: $unit;
            a {
              display: block;
            }
          }
        }
      }

      &.prefectures {
        a {
          display: inline-block;
          padding: 4px;
          margin-right: $unit/2;
          transition: color .4s;
          &:hover {
            color: #3c40c6;
          }
        }
      }
    }
  }

  .nanq {
    background: rgb(255, 221, 51);
    margin-bottom: $unit;
    .header {
      text-align: center;
      padding: $unit;
      h1 {
        color: #e5382d;
        @include fs(2);
      }
    }

    .body {
      padding: $unit;
      a {
        display: block;
        padding: $unit/2 $unit;
        border: 2px solid #e5382d;
        color: #e5382d;
        font-weight: bold;
        text-align: center;
        background: white;
        margin-bottom: $unit/2;
      }
    }

  }

  .section-recruit {
    .body {
      a {
        display: block;
        padding: 4px 0;
      }
    }
  }

  .section-new-jobs {

    header {
      background: rgb(255, 221, 51);
      padding: $unit/2 $unit/2;
      h1 {
        font-weight: bold;
      }
    }

    .body {
      padding: $unit 0;
      .text {
        padding-left: $unit/2;
      }
      a {
        .ymd {
          @include fs(-2);
          font-weight: bold;
          color: #AAA;
        }

        &:hover {
          opacity: .8;
        }
      }
    }
  }

  .section-recruit-job-tags {
    background: #f0f6ff;

    header {
      text-align: center;
      h1 {
        @include fs(1);
        font-weight: lighter;
      }
    }
    a.tag {
      display: inline-block;
      background: #3c7df4;
      color: white;
      padding: 2px 8px;
      margin-right: 4px;
      margin-bottom: 4px;
      border-radius: 2px;
    }
  }

  .section-recruit-corporate {
    background: whitesmoke;
    color: white;
    border-radius: 4px;

    header {
      background: #E52A2A;
      color: white;
      padding: $unit;
      text-align: center;
      //border-bottom: 1px solid lighten(black, 24%);
      h1 {
        font-weight: bold;
      }
    }

    .body {
      padding: $unit;
      color: black;
      a {
        //color: white;
      }
      dl {
        margin-bottom: $unit/2;
        dt {
          font-weight: bold;
        }
        dd {
          @include fs();
        }
      }
    }

    footer {
      a {
        background: #E52A2A;
        display: block;
        padding: $unit;
        text-align: center;
        color: white;
        text-decoration: none;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  .section-corporate-jobs {
    header {
      padding: $unit/2 0;
      border-bottom: 2px solid black;
      h1 {
        font-weight: bold;
      }
    }

    .body {
      padding: $unit 0;
    }

    a.corporate-job {
      .text {
        padding-left: $unit;
        h1 {
          font-weight: bold;
        }
      }
    }
  }

  .section-blog-category {
    margin-bottom: $unit*2;

    .section-header {
      h1 {
        padding: $unit/2 0;
        border-bottom: 2px solid black;
        @include fs(1);
      }
    }

    .body {
      a {
        display: block;
        border-bottom: 1px dotted whitesmoke;
        padding: $unit $unit/2;
        @include fs();
      }
    }
  }
}

/****************************************************************************
 * フッタ
 ****************************************************************************/
.l-footer {
  background: #4d515a;
  color: white;
  padding: $unit;

  .nav-pages {
    text-align: center;
    padding: $unit 0;
    a {
      color: white;
      text-decoration: none;
      display: inline-block;
      padding: 0 $unit;
      @include fs(-1);
      &:hover {
        text-decoration: underline;
      }

    }
  }

  .copyright {
    text-align: center;
  }
}







