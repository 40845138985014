/***************************************************************
 * なんQ、掲示板の画像ポップアップ
 ***************************************************************/

#subback {
    background-color: rgba(0,0,0,0.5);
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1000;
    display:none;
}
 
img.seimg{
   max-width: 200px;
}

img.seimg:hover , img.sebimg:hover {
    cursor:pointer;
}
 
img.sebimg {
    position:absolute;
    display:none;   
    z-index:1500;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
    border:10px solid #fff;
}
