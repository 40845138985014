.page-bbs-comment {

  /*-----------------------------------------------------
   * 質問
   *-----------------------------------------------------*/
  .question {
    border: 1px solid #ffdd33;
    margin-bottom: $unit*2;
    background: rgba(#ffdd33, .1);
    header {
      padding: $unit;

      .thread-date {
        > div {
          display: inline-block;

          &.category {
            margin-left: $unit;
          }
        }

        .label {
          font-weight: bold;
        }
      }
      .question-title {
        @include fs(2);
        font-weight: bold;
      }
    }

    .post-user {
      padding: $unit/2 $unit;
      display: flex;
      width: 100%;
      align-items: center;
      border-top: 1px solid whitesmoke;
      background: whitesmoke;
      .photo {
        flex-basis: 8%;
        img {
          width: 100%;
        }
      }

      .user {
        flex-basis: 40%;
        padding-left: $unit;
      }

      .sns-buttons {
        flex-basis: 52%;
        text-align: right;
      }
    }

    .detail-text {
      padding: $unit;
      .flex {
        display: flex;
        .photo {
          flex-basis: 24%;
          img {
            width: 100%;
          }
        }
        .text {
          flex-basis: 76%;
          padding-left: $unit;
        }
      }
    }

    .actions {
      padding: $unit;
      text-align: center;
    }

  }

  .alert-message {
    text-align: center;
    p {
      @include fs(2);
    }
  }


  /*-----------------------------------------------------
   * 解答
   *-----------------------------------------------------*/

  .answer {
    margin-bottom: $unit;
    padding: $unit;
    border-bottom: 1px dotted #ccc;
    .answer-user {
      display: flex;
      .photo {
        flex-basis: 8%;
      }

      .profile {
        padding-left: $unit;
        flex-basis: 92%;
        span {
          display: block
        }
      }
    }

    .text-box {
      padding: $unit 0;
      .flex {
        display: flex;
        .photo {
          flex-basis: 16%;
        }
        .text {
          padding-left: $unit;
          flex-basis: 84%;
        }
      }
    }

    footer {
      display: flex;
      .actions {
        flex-basis: 50%;
        a {
          text-decoration: none;
        }
      }
      .date {
        text-align: right;
        flex-basis: 50%;
      }
    }
  }
}