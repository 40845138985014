$font-size: (
        x-9: 5.4rem,
        x-8: 5.0rem,
        x-7: 4.6rem,
        x-6: 4.2rem,
        x-5: 3.8rem,
        x-4: 3.4rem,
        x-3: 3.0rem,
        x-2: 2.6rem,
        x-1: 2.0rem,
        base: 1.6rem,
        s-1: 1.4rem,
        s-2: 1.2rem,
        s-3: 1.0rem
);
$unit: 1.6rem;