.module-quizzes {

  width: 100%;
  border: 4px solid whitesmoke;
  header {
    padding: $unit;
    text-align: center;
  }

  nav {
    display: flex;
    padding: $unit;
    div {
      flex-basis: 25%;
      padding: 0 $unit;
    }
    a {
      display: block;
      text-decoration: none;
      border: 4px solid #0652DD;
      text-align: center;
      padding: $unit/2;
      font-weight: bold;
      color: #0652DD;

      transition: all .4s;
      &:hover {
        border: 4px solid #4b7bec;
        color: #4b7bec;
      }
    }
  }

}