/***************************************************************************************
 * 求人カテゴリ共通
 **************************************************************************************/
.page-recruit {

  .job-table, .table-corporate-data {
    width: 100%;
    table-layout: fixed;
    border: 1px solid #e2e2e2;

    tbody {
      tr {
        display: block;
        border-bottom: 1px solid #e2e2e2;
        :last-child {
          border-bottom: 0;
        }
      }

      th {
        padding: $unit;
        width: 160px;
        font-weight: bold;
        background: #f4f4f4;
        vertical-align: top;
      }

      td {
        padding: $unit;
      }
    }
  }
}

/***************************************************************************************
 *
 **************************************************************************************/
.page-recruit-job {

  .guide {
    margin-bottom: $unit*2;
    header {
      border-bottom: 1px solid #E52A2A;
      margin-bottom: $unit;
      h1 {
        margin-bottom: 15px;
        padding: 0 0 0 20px;
        font-size: 18px;
        line-height: 30px;
        font-weight: bold;
        border-left: 8px solid #E52A2A;
      }
    }

  }

  // お気に入り
  .fav {
    background: whitesmoke;
    text-align: center;
    padding: $unit;
    margin-top: $unit;
    border-radius: 8px;
    .complete-fav {
      @include fs(2);
      color: #E52A2A;
    }

    .favorite-add {
      background: rgb(255, 221, 51);
      color: black;
      @include fs(2);
      display: inline-block;
      padding: $unit $unit*4;
      border-radius: 4px;
      box-shadow: 0 1px 4px rgba(black, .1);
      border: 1px solid white;
      &:hover {
        cursor: pointer;
      }
    }
  }



  .entry-form {
    border-radius: 4px;
    border: 1px solid whitesmoke;

    header {
      padding: $unit;
      background: #E52A2A;
      h1 {
        color: white;
        font-weight: bold;
      }
    }

    .body {
      padding: $unit;
      .alert {
        padding: $unit 0;
        color: #E52A2A;
      }

      .label {
        font-weight: bold;
      }

      .label-alert {
        @include fs(-1);
        margin-left: $unit/2;
        color: #E52A2A;
      }

      .grid {
        .row {
          padding: $unit 0;
          border-bottom: 1px dotted #ddd;
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }

    footer {
      padding: $unit;
      text-align: center;
      .cv-btn {

      }
    }
  }
}

/***************************************************************************************
 * 企業一覧ページ
 **************************************************************************************/
.page-recruit-corporates {

  .corporate-summary {
    padding: $unit;
    border: 1px solid whitesmoke;
    border-top: 4px solid #E52A2A;
    margin-bottom: $unit;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
    a {
      text-decoration: none;
      color: black;

      h1 {
        color: #E52A2A;
        padding-bottom: $unit/2;
        font-weight: bold;
        @include fs(1);
        border-bottom: 1px solid whitesmoke;
        margin-bottom: $unit;
      }
      .data {
        padding-left: $unit;
      }
    }
  }
}

/***************************************************************************************
 * 企業詳細ページ
 **************************************************************************************/
.page-recruit-corporate {

  > header {
    border-bottom: 1px solid whitesmoke;
    margin-bottom: $unit;
    h1 {
      @include fs(2);
      display: inline-block;

    }
  }

  .corporate-jobs {
    padding: $unit*2 0;
    > header {
      text-align: center;
      h1 {
        position: relative;
        @include fs(2);
        display: inline-block;
        margin-bottom: $unit;

        &:before {
          content: '';
          position: absolute;
          left: 50%;
          bottom: -15px; /*線の上下位置*/
          display: inline-block;
          width: 60px; /*線の長さ*/
          height: 2px; /*線の太さ*/
          transform: translateX(-50%); /*位置調整*/
          background-color: #E52A2A;
          border-radius: 2px;
        }

      }
    }

  }
}



.page-recruit-job-confirm {

  .form-confirm {
    footer {
      text-align: center;
    }

    .row {
      border-bottom: 1px solid whitesmoke;
      .col {
        padding: $unit $unit;
      }
    }
    .header {
      font-weight: 800;
      .alert {
        color: #E52A2A;
        @include fs(-1);
      }
    }
  }

}

/***************************************************************************************
 * 応募完了ページ
 **************************************************************************************/
.page-recruit-job-entry-complete {

  header {
    h1 {
      @include fs(3);
    }
  }

  .body {

  }

}