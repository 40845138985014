.form-confirm {
  &.bordered {
    border: 1px solid whitesmoke;
  }

  header {
    &.default {
      padding: $unit;
      border-bottom: 1px solid whitesmoke;
      .title {
        @include fs(2);
      }
    }

  }

  .body {
    padding: $unit;
    p {
      margin-bottom: $unit;
    }
    dl {
      margin-bottom: $unit;

      dt {
        font-weight: bold;
      }

      dd {

      }
    }
  }

  footer {
    padding: $unit;
    .submit {
      display: inline-block;
    }
    &.default {
      padding: $unit;
      border-top: 1px solid whitesmoke;
    }

    .actions {
      &.center {
        text-align: center;
      }
      &.right {
        text-align: right;
      }
    }
  }
}