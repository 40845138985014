/***************************************************************************************
 * 大学受験コミュニティ HOME
 **************************************************************************************/
.page-daigakujuken {

  /*-----------------------------------------------------------------------
   * なんきゅー
   *-----------------------------------------------------------------------*/
  .content-nanq {
    margin-bottom: $unit*2;
    .body {
      .thread {
        border-bottom: 1px dotted whitesmoke;
        padding: $unit/2 0;

        .thread-data {
          > div {
            display: inline-block;
          }
        }
        .ymd-his {
          display: inline-block;
          margin-right: $unit;
        }

        .kind {

        }

        .label-kind {
          background: #ffdd33;
          display: inline-block;
          padding: 2px $unit;
          @include fs(-1);
        }

        .data {
          a {
            color: #4b7bec;
          }

          .title {
            @include fs();
            font-weight: bold;
          }

          .desc {
            @include fs(-1);
          }
        }
      }
    }
  }



  /*-----------------------------------------------------------------------
   * この問題どう指導しますか
   *-----------------------------------------------------------------------*/
  .content-bbs {
    margin-bottom: $unit*2;
    .body {
      .thread {
        border-bottom: 1px dotted whitesmoke;
        padding: $unit/2 0;

        .thread-data {
          > div {
            display: inline-block;
          }
        }
        .ymd-his {
          display: inline-block;
          margin-right: $unit;
        }

        .kind {

        }

        .label-kind {
          background: #ffdd33;
          display: inline-block;
          padding: 2px $unit;
          @include fs(-1);
        }

        .data {
          a {
            color: #4b7bec;
          }

          .title {
            @include fs();
            font-weight: bold;
          }

          .desc {
            @include fs(-1);
          }
        }
      }
    }
  }

  .content-quizzes {
    background: url("/assets/daigakujuken/index/images/quizzes.bg.png") no-repeat 0 0;
    background-size: contain;
    min-height: 200px;
    text-align: center;
    padding: $unit;
    width: 100%;
    display: table;
    margin-bottom: $unit*2;

    .inner {
      display: table-cell;
      vertical-align: bottom;
    }

    .btn {
      display: inline-block;
      vertical-align: bottom;
      padding: $unit $unit*4;
      border: 2px solid white;
      color: white;
      line-height: 1;
      &:hover {

        background: rgba(white, .1);
      }
    }
  }


  /*-----------------------------------------------------------
   * 読み物
   *-----------------------------------------------------------*/
  .content-blog {
    border: 1px solid whitesmoke;

    .title-nav {
      display: flex;
      background: #1abf9f;
      align-items: center;
      border: 1px solid white;
      .titles {
        padding: $unit/2 $unit;
        color: white;
        flex-basis: 80%;

        .section-title {
          font-weight: bold;

        }
      }

      nav {
        padding: $unit/2 $unit;
        flex-basis: 20%;
        text-align: right;

        a {
          display: inline-block;
          color: white;
          @include fs(3);
        }
      }


    }

    .body {
      padding: $unit;
      .post-summary {
        display: flex;
        margin-bottom: $unit;
        .label {
          background: #1abf9f;
          display: inline-block;
          color: white;
          padding: 2px $unit;
          @include fs(-1);
        }

        .content {
          padding-left: $unit;

          .title {
            @include fs();
            font-weight: bold;
          }
          .desc {
            @include fs(-1);
          }

        }


      }
    }
  }
}

