.page-home {


  .headline {
    color: white;
    padding: $unit/2 $unit;
  }

  .nan-q {
    margin-bottom: $unit;
    background: url("/assets/home/images/bg.nanq.gif") no-repeat 0 0;
    background-size: cover;
    padding: 180px $unit $unit $unit;
    nav {
      display: flex;
      > div {
        flex-basis: 33.3333%;
        padding: 0 4px;
      }
      a {
        display: block;
        font-weight: bold;
        border: 2px solid rgba(white, 1);
        padding: $unit;
        color: white;
        text-decoration: none;
        text-align: center;
        transition: all .4s;
        background: rgba(black, .2);
        &:hover {
          background: rgba(white, .2);
        }
      }
    }
  }

  //----------------------------------
  // 年収診断
  //----------------------------------
  .section-nensyu {
    background: linear-gradient(to top, #F45C43, #EB3349); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    padding: $unit;
    margin-bottom: $unit*2;
    h1 {
      color: white;
      @include fs(1);
      text-align: center;
      margin-bottom: $unit*2;
      .line {
        display: block;
        &.line-2 {
          @include fs(3);
          font-weight: bold;
        }
      }
    }

    .nensy {

      display: flex;
      > div {
        flex-basis: 33.3333%;
        padding: 0 4px;
      }
      a {
        display: block;

        border: 2px solid rgba(white, 1);
        padding: $unit;
        color: white;
        text-decoration: none;
        text-align: center;
        transition: all .4s;
        &:hover {
          background: rgba(white, .2);
        }
      }
    }
  }


  //----------------------------------
  // コミュニティリンク
  //----------------------------------
  .commu-categories {
    display: flex;
    margin-bottom: $unit;
    > div {
      flex-basis: 33.333333%;
      padding: 4px;

    }

    a {
      text-decoration: none;
      display: block;
      border: 1px solid whitesmoke;
      padding: 4px;
    }
    h1 {
      font-weight: bold;
      color: black;
      @include fs(-1);
      .label {
        display: inline-block;
        margin-right: $unit/2;
        color: white;
        padding: 4px $unit/2;
        font-weight: lighter;
      }
    }

    .chugaku {
      .label {
        background: #20bf6b;
      }
    }
    .koukou {
      .label {
        background: #eb3b5a;
      }
    }
    .daigaku {
      .label {
        background: #4b7bec;
      }
    }
  }

  //----------------------------------
  // コミュニティリンク
  //----------------------------------
  .contents {
    display: flex;
    margin-bottom: $unit*2;
    .content {
      flex-basis: 33.333333%;
      padding: 4px;
      div {
        border: 1px solid whitesmoke;
        padding: 4px;
      }
    }

    a {
      text-decoration: none;
      display: block;
      border: 1px solid whitesmoke;
      padding: 4px;
    }

    h1 {
      font-weight: bold;
      color: black;
      @include fs(-1);
      .label {

        display: inline-block;
        margin-right: $unit/2;
        color: white;
        padding: 4px 4px;
        font-weight: lighter;
      }
    }

    .read {
      .label {
        background: #20bf6b;
      }
    }
    .news {
      .label {
        background: #eb3b5a;
      }
    }
    .present {
      .label {
        background: #4b7bec;
      }
    }
  }


  //----------------------------------
  // コミュニティリンク
  //----------------------------------
  .point-up {
    margin-bottom: $unit*2;
    border: 1px solid whitesmoke;
    padding: $unit;
    header {
      background: whitesmoke;
      padding: $unit/2 $unit;
      h1 {
        @include fs(1);
        color: black;

      }
    }
  }
  //----------------------------------
  // ランキング、新着
  //----------------------------------
  .ranking-news {
    display: flex;
    .ranking {
      flex-basis: 30%;
      background: lighten(#26de81, 32%);
      header {
        background: #26de81;
        padding: $unit/2;
      }
    }

    .news {
      flex-basis: 70%;
      padding-left: 32px;
      //background: lighten(#fc5c65, 24%);
      header {
        background: #fc5c65;
        color: white;
        padding: $unit/2;
      }

      .body {
        padding: $unit 0;
        a.record {
          text-decoration: none;
          color: black;
          padding: 4px 0;
          transition: color .4s;
          display: block;
          border-bottom: 1px dotted whitesmoke;
          span {
            display: inline-block;
            &.date {
              font-weight: bold;
            }
          }

          &:hover {
            color: blue;
          }
        }
      }
    }
  }
}