/********************************************************
 * 業界ニュース
 ********************************************************/
.page-curation {

  .date {
    font-weight: bold;
    display: block;
    margin-bottom: $unit;
  }

  // 元記事用
  .origin {
    margin-bottom: $unit*3;
    color: #333;
    padding: $unit;
    background: #efefef;
    border-radius: 8px;

    blockquote {
      position: relative;
      padding: 10px 15px 10px 60px;
      box-sizing: border-box;
      font-style: italic;
      color: #555;

    }

    blockquote:before{
      display: inline-block;
      position: absolute;
      top: 10px;
      left: -3px;
      vertical-align: middle;
      content: "❛❛";
      font-family: sans-serif;
      color: #cfcfcf;
      font-size: 90px;
      line-height: 1;
    }

    blockquote p {
      padding: 0;
      margin: 10px 0;
      line-height: 1.7;
    }

    blockquote cite {
      display: block;
      text-align: right;
      color: #888888;
      font-size: 0.9em;
    }


    .title {
      @include fs(1);
      margin-bottom: $unit;
    }
  }

  // 投稿
  .my {
    padding: $unit*2;

    h1, h2, h3, h4, h5, h6,
    p, li, dt, dd, span
    {
      line-height: 1.6666;
    }

    h1, h2, h3, h4, h5, h6 {
      margin-bottom: $unit;
    }



    .under-line {
      border-bottom: 1px solid #ddd;
      padding-bottom: $unit;
    }

    .title {
      @include fs(3);
      font-weight: bold;
      margin-bottom: $unit;
    }

    .box {
      padding: $unit;
      &.box-line {
        border: 1px solid #ddd;
      }
    }
    .bold {
      font-weight: 800;
    }
    p {
      margin-bottom: $unit;
    }


    dl {
      margin-bottom: $unit;
      dt {
        font-weight: 800;
      }

      dd {
        margin-bottom: $unit/2;
      }
    }
    ul {
      margin-bottom: $unit;
      li {
        margin-bottom: $unit/2;
        &:last-child {
          margin-bottom: 0;
        }
      }

      &.default {
        padding-left: $unit*2;
        li {
          list-style: square;
        }
      }
    }
  }
}

//pager用
.pager_view ul li{ 
    display: inline;
    padding: 5px 10px;
    margin-right: 5px;
    border: 1px solid #CCC;
    border-radius: 5px;
}

.pager_view ul li.active{ 
    background-color: #CCF;
}

.pager_view ul li a{
    text-decoration: none;
}

.pager_view ul li a:hover{
    text-decoration: underline;
}
