.app-form {

  header {
    padding: $unit;
    &.default {
      border-bottom: 1px solid whitesmoke;
      .title {
        @include fs(2);
      }
    }
  }

  footer {
    &.default {
      padding: $unit;
      border-top: 1px solid whitesmoke;
    }

    &.right {
      text-align: right;
    }
    &.center {
      text-align: center;
    }
  }

  .grid {


    &.grid-style-bordered {
      .row {
        border-bottom: 1px dotted whitesmoke;

        &:nth-child(even) {
          background: whitesmoke;
        }
        &:last-child {
          border-bottom: none;
        }

        .col {
          padding: $unit/2 $unit;
        }
      }
    }
  }

  &.bordered {
    border: 1px solid whitesmoke;

    .body {
      padding: $unit;
      dl {
        margin-bottom: $unit;
        dt {
          font-weight: bold;
        }
      }
    }

    footer {
      padding: $unit;
      .actions {
        &.center {
          text-align: center;
        }
        &.right {
          text-align: center;
        }
      }

    }
  }


}