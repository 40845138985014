.page-blog {

  /**********************************************************************
   * サマリ
   **********************************************************************/
  .post-summary {
    margin-bottom: $unit;
    border: 1px solid whitesmoke;
    border-top: 2px solid #1abc9c;

    header {
      padding: $unit/2 $unit;
      .post-title {
        @include fs(2);
        a {
          color: #1abc9c;
          text-decoration: none;
          transition: all .4s;
          &:hover {
            color: #00a8ff;
            text-decoration: underline;
          }
        }
      }
    }

    .body {
      padding: $unit;
    }

    footer {
      text-align: right;
      padding: $unit/2 $unit;
    }
  }



  .post {
    header {
      border-bottom: 2px solid whitesmoke;
      padding: $unit 0;

      h1 {
        @include fs(3);
      }

      a.category {
        display: inline-block;
        background: #1abc9c;
        color: white;
        padding: 2px $unit;
        text-decoration: none;
        font-weight: bold;
        &:hover {
          background: lighten(#1abc9c, .7);
        }
      }
    }

    .body {
      padding: $unit;

      h1 {
        @include fs(3);
        margin-bottom: $unit*3;
        font-weight: bold;
      }

      h2 {
        @include fs(2);
        margin-bottom: $unit*2;
      }

      h3 {
        @include fs(1);
        margin-bottom: $unit*2;
      }

      p {
        margin-bottom: $unit*2;
      }

      ul {
        padding-left: $unit*2;
        margin-bottom: $unit;
        li {
          list-style: square;
          margin-bottom: $unit/2;
        }
      }

    }
  }
}