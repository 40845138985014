.page-bbs {

  .bbs-header {
    padding: $unit;
    border-bottom: 1px solid whitesmoke;
    text-align: center;
    h1 {
      @include fs(3);
    }
  }


  .thread {
    margin-bottom: $unit;
    .thread-title {
      font-weight: bold;
      display: inline-block;
      margin-right: $unit;
    }

    .date-time {
      @include fs(-1);
    }
    .desc {
      @include fs(-1);
      line-height: 1.333;
    }
  }

  .section-category {
    border: 1px solid #c0d3eb;
    margin-bottom: $unit*2;
    header {
      display: flex;
      margin-bottom: $unit;
      border-bottom: 1px solid #c0d3eb;
      padding: $unit/2 $unit;
      background: #f1f8ff;
      align-items: center;
      h1 {
        flex-basis: 70%;
        @include fs(1);
        font-weight: bold;
      }
      .action {
        text-align: right;
        flex-basis: 30%;
        /*
        a {
          display: inline-block;
          padding: $unit/2 $unit;
          border: 2px solid #eb3b5a;
          color: #eb3b5a;
          text-decoration: none;
        }
        */
      }
    }

    .body {
      padding: $unit;
    }
  }

  .section-subject {
    border: 1px solid whitesmoke;
    margin-bottom: $unit;

    &.subject-japanese {
      header {
        background: #fa8231;
        color: white;
      }
    }

    &.subject-mathematics {
      header {
        background: #4b7bec;
        color: white;
      }
    }


    header {
      padding: $unit/2 $unit;
      border-bottom: 1px solid whitesmoke;

      h1 {
        @include fs(1);
      }
    }

    //
    .body {


      .section-header {
        padding: $unit/2 $unit;
        h1 {
          @include fs(1);
        }
      }

      .section-body {
        padding: $unit;


      }

      &.categories {
        background: whitesmoke;
        .category {
          display: inline-block;
          padding: 4px;
          margin-right: $unit;
          color: black;
          transition: color .4s;
          &:hover {
            color: blue;
          }
        }
      }
    }
  }


  .section-new-comments {
    margin-bottom: $unit*2;
    header {
      padding: $unit/2 $unit;
      background: #f6f8fa;
      .section-title {
        font-weight: bold;
      }
    }


    .comments {
      .thread {
        padding: $unit/2 $unit;
      }
    }
  }



}


.page-bbs-search {
  .thread-summary {
    margin-bottom: $unit;
    .label-value {
      @include fs(-1);
    }

    .title {
      @include fs(1);
      font-weight: bold;
    }
  }
}