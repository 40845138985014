.module-job-tags {
  width: 100%;
  header {
    padding: $unit/2;
    border-bottom: 2px solid black;
    @include fs(1);
  }

  .body {
    padding: $unit;
    a.tag {
      display: inline-block;
      padding: 4px $unit;
      text-decoration: none;
      background: #fa8231;
      color: white;
      font-weight: bold;
      margin-right: 8px;
      border-radius: 2px;
      transition: all .4s;
      &:hover {
        background: #4b7bec;
      }
    }
  }
}