@charset "UTF-8";
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
  font-size: 1.6rem; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

fieldset, img {
  border: 0; }

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
  font-weight: normal; }

li {
  list-style: none; }

caption, th {
  text-align: left; }

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal; }

q:before, q:after {
  content: ''; }

abbr, acronym {
  border: 0;
  font-variant: normal; }

sup {
  vertical-align: text-top; }

sub {
  vertical-align: text-bottom; }

input, textarea, select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit; }

* {
  box-sizing: border-box; }

html, body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%; }

html {
  background: white;
  font-size: 62.5%; }

body {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif; }

/****************************************************************************
 * ヘッダ
 ****************************************************************************/
.l-header {
  width: 100%;
  padding: 1.6rem 0; }
  .l-header .outline {
    display: flex;
    width: 960px;
    margin: 0 auto;
    align-items: center; }
    .l-header .outline a.brand {
      flex-basis: 50%;
      text-decoration: none;
      color: #555;
      transition: opacity .4s; }
      .l-header .outline a.brand:hover {
        opacity: .8; }
      .l-header .outline a.brand .app-name {
        font-size: 2.6rem;
        font-weight: bold;
        width: 240px; }
        .l-header .outline a.brand .app-name svg {
          vertical-align: bottom; }
      .l-header .outline a.brand .desc {
        font-size: 1.2rem;
        padding-left: 8px; }
    .l-header .outline .nav-user-pages {
      flex-basis: 50%;
      text-align: right; }
      .l-header .outline .nav-user-pages a {
        display: inline-block;
        padding: 0.8rem 3.2rem;
        color: white;
        font-weight: bold;
        text-decoration: none; }
      .l-header .outline .nav-user-pages .btn-register {
        background: #f7b731; }
      .l-header .outline .nav-user-pages .btn-login {
        background: #eb3b5a; }

/****************************************************************************
 * グローバルナビ
 ****************************************************************************/
.nav-global {
  border-bottom: 1px solid whitesmoke; }
  .nav-global .outline {
    width: 960px;
    margin: 0 auto;
    display: flex; }
  .nav-global a {
    text-decoration: none;
    display: inline-block; }
  .nav-global .nav-commu {
    align-self: flex-end;
    flex-basis: 55%; }
    .nav-global .nav-commu a {
      padding: 0.8rem 1.6rem;
      font-weight: bold;
      color: black;
      transition: color .4s; }
      .nav-global .nav-commu a:hover {
        color: #4b7bec; }
  .nav-global .nav-recruit {
    text-align: right;
    flex-basis: 45%; }
    .nav-global .nav-recruit a {
      color: white;
      padding: 0.8rem 1.6rem;
      text-align: center; }
      .nav-global .nav-recruit a.item-chugaku {
        background: #0fb9b1; }
      .nav-global .nav-recruit a.item-koukou {
        background: #fa8231; }
      .nav-global .nav-recruit a.item-daigaku {
        background: #4b7bec; }
    .nav-global .nav-recruit span.prefix, .nav-global .nav-recruit span.main-text, .nav-global .nav-recruit span.suffix {
      display: block;
      font-size: 1.4rem; }
    .nav-global .nav-recruit span.main-text {
      font-size: 2rem;
      font-weight: bold; }

/****************************************************************************
 * メイン
 ****************************************************************************/
.l-contents {
  padding: 3.2rem 0; }
  .l-contents > .outline {
    display: flex;
    width: 960px;
    margin: 0 auto; }
    .l-contents > .outline main {
      flex-basis: 72%; }
    .l-contents > .outline .nav-sidebar {
      flex-basis: 28%; }

/****************************************************************************
 * パンくず
 ****************************************************************************/
.nav-topic-path {
  border-bottom: 1px solid whitesmoke;
  font-size: 1.2rem;
  padding: 0.8rem 1.6rem;
  background: white; }
  .nav-topic-path .outline {
    width: 960px;
    margin: 0 auto; }
  .nav-topic-path .separate {
    display: inline-block;
    padding: 0 0.8rem; }
  .nav-topic-path a {
    text-decoration: none;
    color: black;
    transition: all .4s; }
    .nav-topic-path a:hover {
      color: #4b7bec; }

/****************************************************************************
 * サイドバー
 ****************************************************************************/
.nav-sidebar {
  padding-left: 1.6rem; }
  .nav-sidebar h1, .nav-sidebar p, .nav-sidebar dt, .nav-sidebar dd, .nav-sidebar a {
    font-size: 1.4rem; }
  .nav-sidebar a {
    text-decoration: none;
    color: black; }
  .nav-sidebar a.btn-first-guide {
    display: block;
    margin-bottom: 1.6rem;
    background: #4b7bec;
    color: white;
    font-weight: bold;
    padding: 3.2rem 1.6rem;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color .4s; }
    .nav-sidebar a.btn-first-guide span {
      display: block; }
      .nav-sidebar a.btn-first-guide span.prefix {
        font-size: 2.6rem; }
    .nav-sidebar a.btn-first-guide:hover {
      background: #fa8231; }
  .nav-sidebar .section {
    border: 1px solid whitesmoke;
    margin-bottom: 1.6rem; }
    .nav-sidebar .section header {
      padding: 1.6rem;
      border-bottom: 1px solid whiteSmoke; }
      .nav-sidebar .section header h1 {
        font-weight: bold; }
    .nav-sidebar .section .body {
      padding: 1.6rem; }
      .nav-sidebar .section .body dl.default dt {
        font-weight: bold; }
      .nav-sidebar .section .body dl.default dd {
        padding-left: 1.6rem; }
        .nav-sidebar .section .body dl.default dd a {
          display: block; }
      .nav-sidebar .section .body.prefectures a {
        display: inline-block;
        padding: 4px;
        margin-right: 0.8rem;
        transition: color .4s; }
        .nav-sidebar .section .body.prefectures a:hover {
          color: #3c40c6; }
  .nav-sidebar .nanq {
    background: #ffdd33;
    margin-bottom: 1.6rem; }
    .nav-sidebar .nanq .header {
      text-align: center;
      padding: 1.6rem; }
      .nav-sidebar .nanq .header h1 {
        color: #e5382d;
        font-size: 2.6rem; }
    .nav-sidebar .nanq .body {
      padding: 1.6rem; }
      .nav-sidebar .nanq .body a {
        display: block;
        padding: 0.8rem 1.6rem;
        border: 2px solid #e5382d;
        color: #e5382d;
        font-weight: bold;
        text-align: center;
        background: white;
        margin-bottom: 0.8rem; }
  .nav-sidebar .section-recruit .body a {
    display: block;
    padding: 4px 0; }
  .nav-sidebar .section-new-jobs header {
    background: #ffdd33;
    padding: 0.8rem 0.8rem; }
    .nav-sidebar .section-new-jobs header h1 {
      font-weight: bold; }
  .nav-sidebar .section-new-jobs .body {
    padding: 1.6rem 0; }
    .nav-sidebar .section-new-jobs .body .text {
      padding-left: 0.8rem; }
    .nav-sidebar .section-new-jobs .body a .ymd {
      font-size: 1.2rem;
      font-weight: bold;
      color: #AAA; }
    .nav-sidebar .section-new-jobs .body a:hover {
      opacity: .8; }
  .nav-sidebar .section-recruit-job-tags {
    background: #f0f6ff; }
    .nav-sidebar .section-recruit-job-tags header {
      text-align: center; }
      .nav-sidebar .section-recruit-job-tags header h1 {
        font-size: 2rem;
        font-weight: lighter; }
    .nav-sidebar .section-recruit-job-tags a.tag {
      display: inline-block;
      background: #3c7df4;
      color: white;
      padding: 2px 8px;
      margin-right: 4px;
      margin-bottom: 4px;
      border-radius: 2px; }
  .nav-sidebar .section-recruit-corporate {
    background: whitesmoke;
    color: white;
    border-radius: 4px; }
    .nav-sidebar .section-recruit-corporate header {
      background: #E52A2A;
      color: white;
      padding: 1.6rem;
      text-align: center; }
      .nav-sidebar .section-recruit-corporate header h1 {
        font-weight: bold; }
    .nav-sidebar .section-recruit-corporate .body {
      padding: 1.6rem;
      color: black; }
      .nav-sidebar .section-recruit-corporate .body dl {
        margin-bottom: 0.8rem; }
        .nav-sidebar .section-recruit-corporate .body dl dt {
          font-weight: bold; }
        .nav-sidebar .section-recruit-corporate .body dl dd {
          font-size: 1.6rem; }
    .nav-sidebar .section-recruit-corporate footer a {
      background: #E52A2A;
      display: block;
      padding: 1.6rem;
      text-align: center;
      color: white;
      text-decoration: none;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px; }
  .nav-sidebar .section-corporate-jobs header {
    padding: 0.8rem 0;
    border-bottom: 2px solid black; }
    .nav-sidebar .section-corporate-jobs header h1 {
      font-weight: bold; }
  .nav-sidebar .section-corporate-jobs .body {
    padding: 1.6rem 0; }
  .nav-sidebar .section-corporate-jobs a.corporate-job .text {
    padding-left: 1.6rem; }
    .nav-sidebar .section-corporate-jobs a.corporate-job .text h1 {
      font-weight: bold; }
  .nav-sidebar .section-blog-category {
    margin-bottom: 3.2rem; }
    .nav-sidebar .section-blog-category .section-header h1 {
      padding: 0.8rem 0;
      border-bottom: 2px solid black;
      font-size: 2rem; }
    .nav-sidebar .section-blog-category .body a {
      display: block;
      border-bottom: 1px dotted whitesmoke;
      padding: 1.6rem 0.8rem;
      font-size: 1.6rem; }

/****************************************************************************
 * フッタ
 ****************************************************************************/
.l-footer {
  background: #4d515a;
  color: white;
  padding: 1.6rem; }
  .l-footer .nav-pages {
    text-align: center;
    padding: 1.6rem 0; }
    .l-footer .nav-pages a {
      color: white;
      text-decoration: none;
      display: inline-block;
      padding: 0 1.6rem;
      font-size: 1.4rem; }
      .l-footer .nav-pages a:hover {
        text-decoration: underline; }
  .l-footer .copyright {
    text-align: center; }

.article header.m-default {
  border-bottom: 1px solid whitesmoke;
  padding: 1.6rem; }
  .article header.m-default .article-title {
    font-size: 3.4rem; }

.article .body.m-default {
  padding: 1.6rem; }
  .article .body.m-default .content {
    margin-bottom: 4.8rem; }
    .article .body.m-default .content .block {
      margin-bottom: 3.2rem; }
  .article .body.m-default a {
    color: #1a0dab;
    transition: color .4s; }
    .article .body.m-default a:hover {
      color: #4b7bec; }
  .article .body.m-default h1, .article .body.m-default h2, .article .body.m-default h3, .article .body.m-default h4, .article .body.m-default h5, .article .body.m-default h6 {
    margin-bottom: 1.6rem; }
  .article .body.m-default .headline-lv1 {
    font-size: 2.6rem;
    font-weight: bold; }
  .article .body.m-default .headline-lv2 {
    font-size: 2rem;
    font-weight: bold; }
  .article .body.m-default .headline-lv3 {
    font-size: 1.6rem;
    font-weight: bold; }
  .article .body.m-default .fc-red {
    color: #eb3b5a; }
  .article .body.m-default p, .article .body.m-default ul, .article .body.m-default dl {
    margin-bottom: 1.6rem; }
  .article .body.m-default ul {
    padding-left: 3.2rem; }
    .article .body.m-default ul li {
      list-style: square; }

.article .body dl.m-default {
  margin-bottom: 1.6rem; }
  .article .body dl.m-default dt {
    font-weight: bold; }

.page-home .headline {
  color: white;
  padding: 0.8rem 1.6rem; }

.page-home .nan-q {
  margin-bottom: 1.6rem;
  background: url("/assets/home/images/bg.nanq.gif") no-repeat 0 0;
  background-size: cover;
  padding: 180px 1.6rem 1.6rem 1.6rem; }
  .page-home .nan-q nav {
    display: flex; }
    .page-home .nan-q nav > div {
      flex-basis: 33.3333%;
      padding: 0 4px; }
    .page-home .nan-q nav a {
      display: block;
      font-weight: bold;
      border: 2px solid white;
      padding: 1.6rem;
      color: white;
      text-decoration: none;
      text-align: center;
      transition: all .4s;
      background: rgba(0, 0, 0, 0.2); }
      .page-home .nan-q nav a:hover {
        background: rgba(255, 255, 255, 0.2); }

.page-home .section-nensyu {
  background: linear-gradient(to top, #F45C43, #EB3349);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding: 1.6rem;
  margin-bottom: 3.2rem; }
  .page-home .section-nensyu h1 {
    color: white;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 3.2rem; }
    .page-home .section-nensyu h1 .line {
      display: block; }
      .page-home .section-nensyu h1 .line.line-2 {
        font-size: 3rem;
        font-weight: bold; }
  .page-home .section-nensyu .nensy {
    display: flex; }
    .page-home .section-nensyu .nensy > div {
      flex-basis: 33.3333%;
      padding: 0 4px; }
    .page-home .section-nensyu .nensy a {
      display: block;
      border: 2px solid white;
      padding: 1.6rem;
      color: white;
      text-decoration: none;
      text-align: center;
      transition: all .4s; }
      .page-home .section-nensyu .nensy a:hover {
        background: rgba(255, 255, 255, 0.2); }

.page-home .commu-categories {
  display: flex;
  margin-bottom: 1.6rem; }
  .page-home .commu-categories > div {
    flex-basis: 33.333333%;
    padding: 4px; }
  .page-home .commu-categories a {
    text-decoration: none;
    display: block;
    border: 1px solid whitesmoke;
    padding: 4px; }
  .page-home .commu-categories h1 {
    font-weight: bold;
    color: black;
    font-size: 1.4rem; }
    .page-home .commu-categories h1 .label {
      display: inline-block;
      margin-right: 0.8rem;
      color: white;
      padding: 4px 0.8rem;
      font-weight: lighter; }
  .page-home .commu-categories .chugaku .label {
    background: #20bf6b; }
  .page-home .commu-categories .koukou .label {
    background: #eb3b5a; }
  .page-home .commu-categories .daigaku .label {
    background: #4b7bec; }

.page-home .contents {
  display: flex;
  margin-bottom: 3.2rem; }
  .page-home .contents .content {
    flex-basis: 33.333333%;
    padding: 4px; }
    .page-home .contents .content div {
      border: 1px solid whitesmoke;
      padding: 4px; }
  .page-home .contents a {
    text-decoration: none;
    display: block;
    border: 1px solid whitesmoke;
    padding: 4px; }
  .page-home .contents h1 {
    font-weight: bold;
    color: black;
    font-size: 1.4rem; }
    .page-home .contents h1 .label {
      display: inline-block;
      margin-right: 0.8rem;
      color: white;
      padding: 4px 4px;
      font-weight: lighter; }
  .page-home .contents .read .label {
    background: #20bf6b; }
  .page-home .contents .news .label {
    background: #eb3b5a; }
  .page-home .contents .present .label {
    background: #4b7bec; }

.page-home .point-up {
  margin-bottom: 3.2rem;
  border: 1px solid whitesmoke;
  padding: 1.6rem; }
  .page-home .point-up header {
    background: whitesmoke;
    padding: 0.8rem 1.6rem; }
    .page-home .point-up header h1 {
      font-size: 2rem;
      color: black; }

.page-home .ranking-news {
  display: flex; }
  .page-home .ranking-news .ranking {
    flex-basis: 30%;
    background: #b4f4d3; }
    .page-home .ranking-news .ranking header {
      background: #26de81;
      padding: 0.8rem; }
  .page-home .ranking-news .news {
    flex-basis: 70%;
    padding-left: 32px; }
    .page-home .ranking-news .news header {
      background: #fc5c65;
      color: white;
      padding: 0.8rem; }
    .page-home .ranking-news .news .body {
      padding: 1.6rem 0; }
      .page-home .ranking-news .news .body a.record {
        text-decoration: none;
        color: black;
        padding: 4px 0;
        transition: color .4s;
        display: block;
        border-bottom: 1px dotted whitesmoke; }
        .page-home .ranking-news .news .body a.record span {
          display: inline-block; }
          .page-home .ranking-news .news .body a.record span.date {
            font-weight: bold; }
        .page-home .ranking-news .news .body a.record:hover {
          color: blue; }

.page-bbs .bbs-header {
  padding: 1.6rem;
  border-bottom: 1px solid whitesmoke;
  text-align: center; }
  .page-bbs .bbs-header h1 {
    font-size: 3rem; }

.page-bbs .thread {
  margin-bottom: 1.6rem; }
  .page-bbs .thread .thread-title {
    font-weight: bold;
    display: inline-block;
    margin-right: 1.6rem; }
  .page-bbs .thread .date-time {
    font-size: 1.4rem; }
  .page-bbs .thread .desc {
    font-size: 1.4rem;
    line-height: 1.333; }

.page-bbs .section-category {
  border: 1px solid #c0d3eb;
  margin-bottom: 3.2rem; }
  .page-bbs .section-category header {
    display: flex;
    margin-bottom: 1.6rem;
    border-bottom: 1px solid #c0d3eb;
    padding: 0.8rem 1.6rem;
    background: #f1f8ff;
    align-items: center; }
    .page-bbs .section-category header h1 {
      flex-basis: 70%;
      font-size: 2rem;
      font-weight: bold; }
    .page-bbs .section-category header .action {
      text-align: right;
      flex-basis: 30%;
      /*
        a {
          display: inline-block;
          padding: $unit/2 $unit;
          border: 2px solid #eb3b5a;
          color: #eb3b5a;
          text-decoration: none;
        }
        */ }
  .page-bbs .section-category .body {
    padding: 1.6rem; }

.page-bbs .section-subject {
  border: 1px solid whitesmoke;
  margin-bottom: 1.6rem; }
  .page-bbs .section-subject.subject-japanese header {
    background: #fa8231;
    color: white; }
  .page-bbs .section-subject.subject-mathematics header {
    background: #4b7bec;
    color: white; }
  .page-bbs .section-subject header {
    padding: 0.8rem 1.6rem;
    border-bottom: 1px solid whitesmoke; }
    .page-bbs .section-subject header h1 {
      font-size: 2rem; }
  .page-bbs .section-subject .body .section-header {
    padding: 0.8rem 1.6rem; }
    .page-bbs .section-subject .body .section-header h1 {
      font-size: 2rem; }
  .page-bbs .section-subject .body .section-body {
    padding: 1.6rem; }
  .page-bbs .section-subject .body.categories {
    background: whitesmoke; }
    .page-bbs .section-subject .body.categories .category {
      display: inline-block;
      padding: 4px;
      margin-right: 1.6rem;
      color: black;
      transition: color .4s; }
      .page-bbs .section-subject .body.categories .category:hover {
        color: blue; }

.page-bbs .section-new-comments {
  margin-bottom: 3.2rem; }
  .page-bbs .section-new-comments header {
    padding: 0.8rem 1.6rem;
    background: #f6f8fa; }
    .page-bbs .section-new-comments header .section-title {
      font-weight: bold; }
  .page-bbs .section-new-comments .comments .thread {
    padding: 0.8rem 1.6rem; }

.page-bbs-search .thread-summary {
  margin-bottom: 1.6rem; }
  .page-bbs-search .thread-summary .label-value {
    font-size: 1.4rem; }
  .page-bbs-search .thread-summary .title {
    font-size: 2rem;
    font-weight: bold; }

.page-bbs-thread {
  /*-----------------------------------------------------
   * 質問
   *-----------------------------------------------------*/
  /*-----------------------------------------------------
 * ベストアンサー
 *-----------------------------------------------------*/
  /*-----------------------------------------------------
 * 解答
 *-----------------------------------------------------*/ }
  .page-bbs-thread .btn-good {
    text-decoration: none; }
  .page-bbs-thread .question {
    border: 1px solid #ffdd33;
    margin-bottom: 3.2rem;
    background: rgba(255, 221, 51, 0.1); }
    .page-bbs-thread .question header {
      padding: 1.6rem; }
      .page-bbs-thread .question header .thread-date > div {
        display: inline-block; }
        .page-bbs-thread .question header .thread-date > div.category {
          margin-left: 1.6rem; }
      .page-bbs-thread .question header .thread-date .label {
        font-weight: bold; }
      .page-bbs-thread .question header .question-title {
        font-size: 2.6rem;
        font-weight: bold; }
    .page-bbs-thread .question .post-user {
      padding: 0.8rem 1.6rem;
      display: flex;
      width: 100%;
      align-items: center;
      border-top: 1px solid rgba(255, 221, 51, 0.5);
      background: white; }
      .page-bbs-thread .question .post-user .photo {
        flex-basis: 8%; }
        .page-bbs-thread .question .post-user .photo img {
          width: 100%; }
      .page-bbs-thread .question .post-user .user {
        flex-basis: 40%;
        padding-left: 1.6rem; }
      .page-bbs-thread .question .post-user .sns-buttons {
        flex-basis: 52%;
        text-align: right; }
    .page-bbs-thread .question .detail-text {
      padding: 1.6rem; }
      .page-bbs-thread .question .detail-text .flex {
        display: flex; }
        .page-bbs-thread .question .detail-text .flex .photo {
          flex-basis: 24%; }
          .page-bbs-thread .question .detail-text .flex .photo img {
            width: 100%; }
        .page-bbs-thread .question .detail-text .flex .text {
          flex-basis: 76%;
          padding-left: 1.6rem; }
    .page-bbs-thread .question .actions {
      padding: 1.6rem;
      text-align: center; }
  .page-bbs-thread .best-answer {
    margin-bottom: 1.6rem; }
    .page-bbs-thread .best-answer > header .title {
      text-align: center;
      font-size: 2.6rem; }
    .page-bbs-thread .best-answer article {
      background: #eef3fb;
      border: 1px solid #6699ff; }
      .page-bbs-thread .best-answer article header {
        padding: 1.6rem; }
        .page-bbs-thread .best-answer article header .title {
          font-size: 2.6rem; }
      .page-bbs-thread .best-answer article .body {
        padding: 1.6rem; }
      .page-bbs-thread .best-answer article footer {
        padding: 0.8rem 1.6rem; }
        .page-bbs-thread .best-answer article footer .grid {
          margin-bottom: 0; }
          .page-bbs-thread .best-answer article footer .grid .row {
            align-items: center; }
    .page-bbs-thread .best-answer .text-box {
      padding: 1.6rem; }
      .page-bbs-thread .best-answer .text-box .flex {
        display: flex; }
        .page-bbs-thread .best-answer .text-box .flex .photo {
          flex-basis: 24%; }
          .page-bbs-thread .best-answer .text-box .flex .photo img {
            width: 100%; }
        .page-bbs-thread .best-answer .text-box .flex .text {
          flex-basis: 76%;
          padding-left: 1.6rem; }
  .page-bbs-thread .answer {
    margin-bottom: 1.6rem;
    border: 1px solid whitesmoke; }
    .page-bbs-thread .answer header {
      padding: 1.6rem; }
      .page-bbs-thread .answer header .title {
        font-size: 3rem; }
    .page-bbs-thread .answer .text-box {
      padding: 1.6rem; }
      .page-bbs-thread .answer .text-box .flex {
        display: flex; }
        .page-bbs-thread .answer .text-box .flex .photo {
          flex-basis: 16%; }
        .page-bbs-thread .answer .text-box .flex .text {
          padding-left: 1.6rem;
          flex-basis: 84%; }
    .page-bbs-thread .answer .answer-user {
      display: flex; }
      .page-bbs-thread .answer .answer-user .photo {
        flex-basis: 8%; }
      .page-bbs-thread .answer .answer-user .profile {
        padding-left: 1.6rem;
        flex-basis: 92%; }
        .page-bbs-thread .answer .answer-user .profile span {
          display: block; }
    .page-bbs-thread .answer footer {
      padding: 0.8rem 1.6rem; }
      .page-bbs-thread .answer footer .grid {
        margin: 0; }
      .page-bbs-thread .answer footer .btn-best-answer {
        padding: 0.8rem 4.8rem;
        background: #e84838;
        color: white;
        border: none;
        border-radius: 4px; }
        .page-bbs-thread .answer footer .btn-best-answer:hover {
          cursor: pointer; }
      .page-bbs-thread .answer footer .row {
        align-items: center; }

.page-bbs-comment {
  /*-----------------------------------------------------
   * 質問
   *-----------------------------------------------------*/
  /*-----------------------------------------------------
   * 解答
   *-----------------------------------------------------*/ }
  .page-bbs-comment .question {
    border: 1px solid #ffdd33;
    margin-bottom: 3.2rem;
    background: rgba(255, 221, 51, 0.1); }
    .page-bbs-comment .question header {
      padding: 1.6rem; }
      .page-bbs-comment .question header .thread-date > div {
        display: inline-block; }
        .page-bbs-comment .question header .thread-date > div.category {
          margin-left: 1.6rem; }
      .page-bbs-comment .question header .thread-date .label {
        font-weight: bold; }
      .page-bbs-comment .question header .question-title {
        font-size: 2.6rem;
        font-weight: bold; }
    .page-bbs-comment .question .post-user {
      padding: 0.8rem 1.6rem;
      display: flex;
      width: 100%;
      align-items: center;
      border-top: 1px solid whitesmoke;
      background: whitesmoke; }
      .page-bbs-comment .question .post-user .photo {
        flex-basis: 8%; }
        .page-bbs-comment .question .post-user .photo img {
          width: 100%; }
      .page-bbs-comment .question .post-user .user {
        flex-basis: 40%;
        padding-left: 1.6rem; }
      .page-bbs-comment .question .post-user .sns-buttons {
        flex-basis: 52%;
        text-align: right; }
    .page-bbs-comment .question .detail-text {
      padding: 1.6rem; }
      .page-bbs-comment .question .detail-text .flex {
        display: flex; }
        .page-bbs-comment .question .detail-text .flex .photo {
          flex-basis: 24%; }
          .page-bbs-comment .question .detail-text .flex .photo img {
            width: 100%; }
        .page-bbs-comment .question .detail-text .flex .text {
          flex-basis: 76%;
          padding-left: 1.6rem; }
    .page-bbs-comment .question .actions {
      padding: 1.6rem;
      text-align: center; }
  .page-bbs-comment .alert-message {
    text-align: center; }
    .page-bbs-comment .alert-message p {
      font-size: 2.6rem; }
  .page-bbs-comment .answer {
    margin-bottom: 1.6rem;
    padding: 1.6rem;
    border-bottom: 1px dotted #ccc; }
    .page-bbs-comment .answer .answer-user {
      display: flex; }
      .page-bbs-comment .answer .answer-user .photo {
        flex-basis: 8%; }
      .page-bbs-comment .answer .answer-user .profile {
        padding-left: 1.6rem;
        flex-basis: 92%; }
        .page-bbs-comment .answer .answer-user .profile span {
          display: block; }
    .page-bbs-comment .answer .text-box {
      padding: 1.6rem 0; }
      .page-bbs-comment .answer .text-box .flex {
        display: flex; }
        .page-bbs-comment .answer .text-box .flex .photo {
          flex-basis: 16%; }
        .page-bbs-comment .answer .text-box .flex .text {
          padding-left: 1.6rem;
          flex-basis: 84%; }
    .page-bbs-comment .answer footer {
      display: flex; }
      .page-bbs-comment .answer footer .actions {
        flex-basis: 50%; }
        .page-bbs-comment .answer footer .actions a {
          text-decoration: none; }
      .page-bbs-comment .answer footer .date {
        text-align: right;
        flex-basis: 50%; }

.page-nanq-search .thread-summary {
  margin-bottom: 1.6rem; }
  .page-nanq-search .thread-summary .label-value {
    font-size: 1.4rem; }
  .page-nanq-search .thread-summary .title {
    font-size: 2rem;
    font-weight: bold; }

.page-nanq-thread {
  /*-----------------------------------------------------
   * 質問
   *-----------------------------------------------------*/
  /*-----------------------------------------------------
   * 解答
   *-----------------------------------------------------*/ }
  .page-nanq-thread .question {
    border: 1px solid #ffdd33;
    margin-bottom: 3.2rem;
    background: rgba(255, 221, 51, 0.1); }
    .page-nanq-thread .question header {
      padding: 1.6rem; }
      .page-nanq-thread .question header .thread-date > div {
        display: inline-block; }
        .page-nanq-thread .question header .thread-date > div.category {
          margin-left: 1.6rem; }
      .page-nanq-thread .question header .thread-date .label {
        font-weight: bold; }
      .page-nanq-thread .question header .question-title {
        font-size: 2.6rem;
        font-weight: bold; }
    .page-nanq-thread .question .post-user {
      padding: 0.8rem 1.6rem;
      display: flex;
      width: 100%;
      align-items: center;
      border-top: 1px solid whitesmoke;
      background: whitesmoke; }
      .page-nanq-thread .question .post-user .photo {
        flex-basis: 8%; }
        .page-nanq-thread .question .post-user .photo img {
          width: 100%; }
      .page-nanq-thread .question .post-user .user {
        flex-basis: 40%;
        padding-left: 1.6rem; }
      .page-nanq-thread .question .post-user .sns-buttons {
        flex-basis: 52%;
        text-align: right; }
    .page-nanq-thread .question .detail-text {
      padding: 1.6rem; }
      .page-nanq-thread .question .detail-text .flex {
        display: flex; }
        .page-nanq-thread .question .detail-text .flex .photo {
          flex-basis: 24%; }
          .page-nanq-thread .question .detail-text .flex .photo img {
            width: 100%; }
        .page-nanq-thread .question .detail-text .flex .text {
          flex-basis: 76%;
          padding-left: 1.6rem; }
    .page-nanq-thread .question .actions {
      padding: 1.6rem;
      text-align: center; }
  .page-nanq-thread .answers {
    padding: 1.6rem; }
  .page-nanq-thread .answer {
    margin-bottom: 1.6rem;
    border: 1px solid whitesmoke;
    border-radius: 4px; }
    .page-nanq-thread .answer header {
      padding: 0.8rem 1.6rem; }
      .page-nanq-thread .answer header .answer-date {
        display: flex; }
        .page-nanq-thread .answer header .answer-date .date {
          flex-basis: 50%;
          font-size: 1.4rem; }
        .page-nanq-thread .answer header .answer-date .related {
          flex-basis: 50%;
          text-align: right;
          font-size: 1.4rem; }
          .page-nanq-thread .answer header .answer-date .related a {
            text-decoration: none;
            color: #4b7bec; }
            .page-nanq-thread .answer header .answer-date .related a:hover {
              text-decoration: underline; }
      .page-nanq-thread .answer header .answer-title {
        font-size: 2rem;
        font-weight: 800; }
    .page-nanq-thread .answer .answer-user {
      display: flex; }
      .page-nanq-thread .answer .answer-user .photo {
        flex-basis: 8%; }
      .page-nanq-thread .answer .answer-user .profile {
        padding-left: 1.6rem;
        flex-basis: 92%; }
        .page-nanq-thread .answer .answer-user .profile span {
          display: block; }
          .page-nanq-thread .answer .answer-user .profile span.subject {
            font-size: 2.6rem; }
    .page-nanq-thread .answer .text-box {
      padding: 1.6rem; }
      .page-nanq-thread .answer .text-box .flex {
        display: flex; }
        .page-nanq-thread .answer .text-box .flex .photo {
          flex-basis: 16%; }
        .page-nanq-thread .answer .text-box .flex .text {
          padding-left: 1.6rem;
          flex-basis: 84%; }
    .page-nanq-thread .answer footer {
      display: flex;
      align-items: center;
      padding: 0.8rem 1.6rem; }
      .page-nanq-thread .answer footer .answer-user {
        flex-basis: 50%; }
        .page-nanq-thread .answer footer .answer-user .photo img {
          vertical-align: middle; }
        .page-nanq-thread .answer footer .answer-user .name {
          display: inline-block;
          margin-left: 0.8rem;
          padding: 0.8rem 0; }
      .page-nanq-thread .answer footer .actions {
        text-align: right;
        flex-basis: 50%; }
        .page-nanq-thread .answer footer .actions a {
          text-decoration: none;
          margin-left: 1.6rem; }

.page-nanq-comment {
  /*-----------------------------------------------------
   * 質問
   *-----------------------------------------------------*/
  /*-----------------------------------------------------
   * 解答
   *-----------------------------------------------------*/ }
  .page-nanq-comment .question {
    border: 1px solid #ffdd33;
    margin-bottom: 3.2rem;
    background: rgba(255, 221, 51, 0.1); }
    .page-nanq-comment .question header {
      padding: 1.6rem; }
      .page-nanq-comment .question header .thread-date > div {
        display: inline-block; }
        .page-nanq-comment .question header .thread-date > div.category {
          margin-left: 1.6rem; }
      .page-nanq-comment .question header .thread-date .label {
        font-weight: bold; }
      .page-nanq-comment .question header .question-title {
        font-size: 2.6rem;
        font-weight: bold; }
    .page-nanq-comment .question .post-user {
      padding: 0.8rem 1.6rem;
      display: flex;
      width: 100%;
      align-items: center;
      border-top: 1px solid whitesmoke;
      background: whitesmoke; }
      .page-nanq-comment .question .post-user .photo {
        flex-basis: 8%; }
        .page-nanq-comment .question .post-user .photo img {
          width: 100%; }
      .page-nanq-comment .question .post-user .user {
        flex-basis: 40%;
        padding-left: 1.6rem; }
      .page-nanq-comment .question .post-user .sns-buttons {
        flex-basis: 52%;
        text-align: right; }
    .page-nanq-comment .question .detail-text {
      padding: 1.6rem; }
      .page-nanq-comment .question .detail-text .flex {
        display: flex; }
        .page-nanq-comment .question .detail-text .flex .photo {
          flex-basis: 24%; }
          .page-nanq-comment .question .detail-text .flex .photo img {
            width: 100%; }
        .page-nanq-comment .question .detail-text .flex .text {
          flex-basis: 76%;
          padding-left: 1.6rem; }
    .page-nanq-comment .question .actions {
      padding: 1.6rem;
      text-align: center; }
  .page-nanq-comment .alert-message {
    text-align: center; }
    .page-nanq-comment .alert-message p {
      font-size: 2.6rem; }
  .page-nanq-comment .answer {
    margin-bottom: 1.6rem;
    padding: 1.6rem;
    border-bottom: 1px dotted #ccc; }
    .page-nanq-comment .answer .answer-user {
      display: flex; }
      .page-nanq-comment .answer .answer-user .photo {
        flex-basis: 8%; }
      .page-nanq-comment .answer .answer-user .profile {
        padding-left: 1.6rem;
        flex-basis: 92%; }
        .page-nanq-comment .answer .answer-user .profile span {
          display: block; }
    .page-nanq-comment .answer .text-box {
      padding: 1.6rem 0; }
      .page-nanq-comment .answer .text-box .flex {
        display: flex; }
        .page-nanq-comment .answer .text-box .flex .photo {
          flex-basis: 16%; }
        .page-nanq-comment .answer .text-box .flex .text {
          padding-left: 1.6rem;
          flex-basis: 84%; }
    .page-nanq-comment .answer footer {
      display: flex; }
      .page-nanq-comment .answer footer .actions {
        flex-basis: 50%; }
        .page-nanq-comment .answer footer .actions a {
          text-decoration: none; }
      .page-nanq-comment .answer footer .date {
        text-align: right;
        flex-basis: 50%; }

.page-news .news-summary {
  margin-bottom: 1.6rem; }
  .page-news .news-summary .news-title a {
    font-size: 3rem;
    color: black;
    text-decoration: none;
    transition: all .4s; }
    .page-news .news-summary .news-title a:hover {
      color: #00a8ff;
      text-decoration: underline; }
  .page-news .news-summary .desc {
    color: #333;
    font-size: 2rem; }
  .page-news .news-summary .ymd {
    padding-top: 8px; }
    .page-news .news-summary .ymd .label {
      padding: 4px 8px;
      color: white;
      font-weight: bold;
      background: #7f8fa6; }

.page-news header {
  padding: 1.6rem; }
  .page-news header .label {
    padding: 4px 1.6rem;
    background: #27ae60;
    color: white;
    font-weight: bold;
    margin-right: 0.8rem;
    display: inline-block; }
  .page-news header .date {
    font-weight: bold;
    display: inline-block; }
  .page-news header h1 {
    margin-top: 0.8rem;
    font-size: 3rem;
    font-weight: bold; }

.page-news .body {
  padding: 1.6rem; }
  .page-news .body .timeline-message {
    font-weight: bold;
    margin-bottom: 1.6rem; }

/********************************************************
 * 業界ニュース
 ********************************************************/
.page-curation .date {
  font-weight: bold;
  display: block;
  margin-bottom: 1.6rem; }

.page-curation .origin {
  margin-bottom: 4.8rem;
  color: #333;
  padding: 1.6rem;
  background: #efefef;
  border-radius: 8px; }
  .page-curation .origin blockquote {
    position: relative;
    padding: 10px 15px 10px 60px;
    box-sizing: border-box;
    font-style: italic;
    color: #555; }
  .page-curation .origin blockquote:before {
    display: inline-block;
    position: absolute;
    top: 10px;
    left: -3px;
    vertical-align: middle;
    content: "❛❛";
    font-family: sans-serif;
    color: #cfcfcf;
    font-size: 90px;
    line-height: 1; }
  .page-curation .origin blockquote p {
    padding: 0;
    margin: 10px 0;
    line-height: 1.7; }
  .page-curation .origin blockquote cite {
    display: block;
    text-align: right;
    color: #888888;
    font-size: 0.9em; }
  .page-curation .origin .title {
    font-size: 2rem;
    margin-bottom: 1.6rem; }

.page-curation .my {
  padding: 3.2rem; }
  .page-curation .my h1, .page-curation .my h2, .page-curation .my h3, .page-curation .my h4, .page-curation .my h5, .page-curation .my h6,
  .page-curation .my p, .page-curation .my li, .page-curation .my dt, .page-curation .my dd, .page-curation .my span {
    line-height: 1.6666; }
  .page-curation .my h1, .page-curation .my h2, .page-curation .my h3, .page-curation .my h4, .page-curation .my h5, .page-curation .my h6 {
    margin-bottom: 1.6rem; }
  .page-curation .my .under-line {
    border-bottom: 1px solid #ddd;
    padding-bottom: 1.6rem; }
  .page-curation .my .title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1.6rem; }
  .page-curation .my .box {
    padding: 1.6rem; }
    .page-curation .my .box.box-line {
      border: 1px solid #ddd; }
  .page-curation .my .bold {
    font-weight: 800; }
  .page-curation .my p {
    margin-bottom: 1.6rem; }
  .page-curation .my dl {
    margin-bottom: 1.6rem; }
    .page-curation .my dl dt {
      font-weight: 800; }
    .page-curation .my dl dd {
      margin-bottom: 0.8rem; }
  .page-curation .my ul {
    margin-bottom: 1.6rem; }
    .page-curation .my ul li {
      margin-bottom: 0.8rem; }
      .page-curation .my ul li:last-child {
        margin-bottom: 0; }
    .page-curation .my ul.default {
      padding-left: 3.2rem; }
      .page-curation .my ul.default li {
        list-style: square; }

.pager_view ul li {
  display: inline;
  padding: 5px 10px;
  margin-right: 5px;
  border: 1px solid #CCC;
  border-radius: 5px; }

.pager_view ul li.active {
  background-color: #CCF; }

.pager_view ul li a {
  text-decoration: none; }

.pager_view ul li a:hover {
  text-decoration: underline; }

/***************************************************************************************
 * 求人カテゴリ共通
 **************************************************************************************/
.page-recruit .job-table, .page-recruit .table-corporate-data {
  width: 100%;
  table-layout: fixed;
  border: 1px solid #e2e2e2; }
  .page-recruit .job-table tbody tr, .page-recruit .table-corporate-data tbody tr {
    display: block;
    border-bottom: 1px solid #e2e2e2; }
    .page-recruit .job-table tbody tr :last-child, .page-recruit .table-corporate-data tbody tr :last-child {
      border-bottom: 0; }
  .page-recruit .job-table tbody th, .page-recruit .table-corporate-data tbody th {
    padding: 1.6rem;
    width: 160px;
    font-weight: bold;
    background: #f4f4f4;
    vertical-align: top; }
  .page-recruit .job-table tbody td, .page-recruit .table-corporate-data tbody td {
    padding: 1.6rem; }

/***************************************************************************************
 *
 **************************************************************************************/
.page-recruit-job .guide {
  margin-bottom: 3.2rem; }
  .page-recruit-job .guide header {
    border-bottom: 1px solid #E52A2A;
    margin-bottom: 1.6rem; }
    .page-recruit-job .guide header h1 {
      margin-bottom: 15px;
      padding: 0 0 0 20px;
      font-size: 18px;
      line-height: 30px;
      font-weight: bold;
      border-left: 8px solid #E52A2A; }

.page-recruit-job .fav {
  background: whitesmoke;
  text-align: center;
  padding: 1.6rem;
  margin-top: 1.6rem;
  border-radius: 8px; }
  .page-recruit-job .fav .complete-fav {
    font-size: 2.6rem;
    color: #E52A2A; }
  .page-recruit-job .fav .favorite-add {
    background: #ffdd33;
    color: black;
    font-size: 2.6rem;
    display: inline-block;
    padding: 1.6rem 6.4rem;
    border-radius: 4px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid white; }
    .page-recruit-job .fav .favorite-add:hover {
      cursor: pointer; }

.page-recruit-job .entry-form {
  border-radius: 4px;
  border: 1px solid whitesmoke; }
  .page-recruit-job .entry-form header {
    padding: 1.6rem;
    background: #E52A2A; }
    .page-recruit-job .entry-form header h1 {
      color: white;
      font-weight: bold; }
  .page-recruit-job .entry-form .body {
    padding: 1.6rem; }
    .page-recruit-job .entry-form .body .alert {
      padding: 1.6rem 0;
      color: #E52A2A; }
    .page-recruit-job .entry-form .body .label {
      font-weight: bold; }
    .page-recruit-job .entry-form .body .label-alert {
      font-size: 1.4rem;
      margin-left: 0.8rem;
      color: #E52A2A; }
    .page-recruit-job .entry-form .body .grid .row {
      padding: 1.6rem 0;
      border-bottom: 1px dotted #ddd; }
      .page-recruit-job .entry-form .body .grid .row:last-child {
        border-bottom: none; }
  .page-recruit-job .entry-form footer {
    padding: 1.6rem;
    text-align: center; }

/***************************************************************************************
 * 企業一覧ページ
 **************************************************************************************/
.page-recruit-corporates .corporate-summary {
  padding: 1.6rem;
  border: 1px solid whitesmoke;
  border-top: 4px solid #E52A2A;
  margin-bottom: 1.6rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12); }
  .page-recruit-corporates .corporate-summary a {
    text-decoration: none;
    color: black; }
    .page-recruit-corporates .corporate-summary a h1 {
      color: #E52A2A;
      padding-bottom: 0.8rem;
      font-weight: bold;
      font-size: 2rem;
      border-bottom: 1px solid whitesmoke;
      margin-bottom: 1.6rem; }
    .page-recruit-corporates .corporate-summary a .data {
      padding-left: 1.6rem; }

/***************************************************************************************
 * 企業詳細ページ
 **************************************************************************************/
.page-recruit-corporate > header {
  border-bottom: 1px solid whitesmoke;
  margin-bottom: 1.6rem; }
  .page-recruit-corporate > header h1 {
    font-size: 2.6rem;
    display: inline-block; }

.page-recruit-corporate .corporate-jobs {
  padding: 3.2rem 0; }
  .page-recruit-corporate .corporate-jobs > header {
    text-align: center; }
    .page-recruit-corporate .corporate-jobs > header h1 {
      position: relative;
      font-size: 2.6rem;
      display: inline-block;
      margin-bottom: 1.6rem; }
      .page-recruit-corporate .corporate-jobs > header h1:before {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -15px;
        /*線の上下位置*/
        display: inline-block;
        width: 60px;
        /*線の長さ*/
        height: 2px;
        /*線の太さ*/
        transform: translateX(-50%);
        /*位置調整*/
        background-color: #E52A2A;
        border-radius: 2px; }

.page-recruit-job-confirm .form-confirm footer {
  text-align: center; }

.page-recruit-job-confirm .form-confirm .row {
  border-bottom: 1px solid whitesmoke; }
  .page-recruit-job-confirm .form-confirm .row .col {
    padding: 1.6rem 1.6rem; }

.page-recruit-job-confirm .form-confirm .header {
  font-weight: 800; }
  .page-recruit-job-confirm .form-confirm .header .alert {
    color: #E52A2A;
    font-size: 1.4rem; }

/***************************************************************************************
 * 応募完了ページ
 **************************************************************************************/
.page-recruit-job-entry-complete header h1 {
  font-size: 3rem; }

.page-blog {
  /**********************************************************************
   * サマリ
   **********************************************************************/ }
  .page-blog .post-summary {
    margin-bottom: 1.6rem;
    border: 1px solid whitesmoke;
    border-top: 2px solid #1abc9c; }
    .page-blog .post-summary header {
      padding: 0.8rem 1.6rem; }
      .page-blog .post-summary header .post-title {
        font-size: 2.6rem; }
        .page-blog .post-summary header .post-title a {
          color: #1abc9c;
          text-decoration: none;
          transition: all .4s; }
          .page-blog .post-summary header .post-title a:hover {
            color: #00a8ff;
            text-decoration: underline; }
    .page-blog .post-summary .body {
      padding: 1.6rem; }
    .page-blog .post-summary footer {
      text-align: right;
      padding: 0.8rem 1.6rem; }
  .page-blog .post header {
    border-bottom: 2px solid whitesmoke;
    padding: 1.6rem 0; }
    .page-blog .post header h1 {
      font-size: 3rem; }
    .page-blog .post header a.category {
      display: inline-block;
      background: #1abc9c;
      color: white;
      padding: 2px 1.6rem;
      text-decoration: none;
      font-weight: bold; }
      .page-blog .post header a.category:hover {
        background: #1abf9f; }
  .page-blog .post .body {
    padding: 1.6rem; }
    .page-blog .post .body h1 {
      font-size: 3rem;
      margin-bottom: 4.8rem;
      font-weight: bold; }
    .page-blog .post .body h2 {
      font-size: 2.6rem;
      margin-bottom: 3.2rem; }
    .page-blog .post .body h3 {
      font-size: 2rem;
      margin-bottom: 3.2rem; }
    .page-blog .post .body p {
      margin-bottom: 3.2rem; }
    .page-blog .post .body ul {
      padding-left: 3.2rem;
      margin-bottom: 1.6rem; }
      .page-blog .post .body ul li {
        list-style: square;
        margin-bottom: 0.8rem; }

/***************************************************************************************
 * 中学受験コミュニティ HOME
 **************************************************************************************/
.page-chugakujuken {
  /*-----------------------------------------------------------------------
   * なんきゅー
   *-----------------------------------------------------------------------*/
  /*-----------------------------------------------------------------------
   * この問題どう指導しますか
   *-----------------------------------------------------------------------*/
  /*-----------------------------------------------------------
   * 読み物
   *-----------------------------------------------------------*/ }
  .page-chugakujuken .content-nanq {
    margin-bottom: 3.2rem; }
    .page-chugakujuken .content-nanq .body .thread {
      border-bottom: 1px dotted whitesmoke;
      padding: 0.8rem 0; }
      .page-chugakujuken .content-nanq .body .thread .thread-data > div {
        display: inline-block; }
      .page-chugakujuken .content-nanq .body .thread .ymd-his {
        display: inline-block;
        margin-right: 1.6rem; }
      .page-chugakujuken .content-nanq .body .thread .label-kind {
        background: #ffdd33;
        display: inline-block;
        padding: 2px 1.6rem;
        font-size: 1.4rem; }
      .page-chugakujuken .content-nanq .body .thread .data a {
        color: #4b7bec; }
      .page-chugakujuken .content-nanq .body .thread .data .title {
        font-size: 1.6rem;
        font-weight: bold; }
      .page-chugakujuken .content-nanq .body .thread .data .desc {
        font-size: 1.4rem; }
  .page-chugakujuken .content-bbs {
    margin-bottom: 3.2rem; }
    .page-chugakujuken .content-bbs .body .thread {
      border-bottom: 1px dotted whitesmoke;
      padding: 0.8rem 0; }
      .page-chugakujuken .content-bbs .body .thread .thread-data > div {
        display: inline-block; }
      .page-chugakujuken .content-bbs .body .thread .ymd-his {
        display: inline-block;
        margin-right: 1.6rem; }
      .page-chugakujuken .content-bbs .body .thread .label-kind {
        background: #ffdd33;
        display: inline-block;
        padding: 2px 1.6rem;
        font-size: 1.4rem; }
      .page-chugakujuken .content-bbs .body .thread .data a {
        color: #4b7bec; }
      .page-chugakujuken .content-bbs .body .thread .data .title {
        font-size: 1.6rem;
        font-weight: bold; }
      .page-chugakujuken .content-bbs .body .thread .data .desc {
        font-size: 1.4rem; }
  .page-chugakujuken .content-quizzes {
    background: url("/assets/chugakujuken/index/images/quizzes.bg.png") no-repeat 0 0;
    background-size: contain;
    min-height: 200px;
    text-align: center;
    padding: 1.6rem;
    width: 100%;
    display: table;
    margin-bottom: 3.2rem; }
    .page-chugakujuken .content-quizzes .inner {
      display: table-cell;
      vertical-align: bottom; }
    .page-chugakujuken .content-quizzes .btn {
      display: inline-block;
      vertical-align: bottom;
      padding: 1.6rem 6.4rem;
      border: 2px solid white;
      color: white;
      line-height: 1; }
      .page-chugakujuken .content-quizzes .btn:hover {
        background: rgba(255, 255, 255, 0.1); }
  .page-chugakujuken .content-blog {
    border: 1px solid whitesmoke; }
    .page-chugakujuken .content-blog .title-nav {
      display: flex;
      background: #1abf9f;
      align-items: center;
      border: 1px solid white; }
      .page-chugakujuken .content-blog .title-nav .titles {
        padding: 0.8rem 1.6rem;
        color: white;
        flex-basis: 80%; }
        .page-chugakujuken .content-blog .title-nav .titles .section-title {
          font-weight: bold; }
      .page-chugakujuken .content-blog .title-nav nav {
        padding: 0.8rem 1.6rem;
        flex-basis: 20%;
        text-align: right; }
        .page-chugakujuken .content-blog .title-nav nav a {
          display: inline-block;
          color: white;
          font-size: 3rem; }
    .page-chugakujuken .content-blog .body {
      padding: 1.6rem; }
      .page-chugakujuken .content-blog .body .post-summary {
        display: flex;
        margin-bottom: 1.6rem; }
        .page-chugakujuken .content-blog .body .post-summary .label {
          background: #1abf9f;
          display: inline-block;
          color: white;
          padding: 2px 1.6rem;
          font-size: 1.4rem; }
        .page-chugakujuken .content-blog .body .post-summary .content {
          padding-left: 1.6rem; }
          .page-chugakujuken .content-blog .body .post-summary .content .title {
            font-size: 1.6rem;
            font-weight: bold; }
          .page-chugakujuken .content-blog .body .post-summary .content .desc {
            font-size: 1.4rem; }

/***************************************************************************************
 * 高校受験コミュニティ HOME
 **************************************************************************************/
.page-koukoujuken {
  /*-----------------------------------------------------------------------
   * なんきゅー
   *-----------------------------------------------------------------------*/
  /*-----------------------------------------------------------------------
   * この問題どう指導しますか
   *-----------------------------------------------------------------------*/
  /*-----------------------------------------------------------
   * 読み物
   *-----------------------------------------------------------*/ }
  .page-koukoujuken .content-nanq {
    margin-bottom: 3.2rem; }
    .page-koukoujuken .content-nanq .body .thread {
      border-bottom: 1px dotted whitesmoke;
      padding: 0.8rem 0; }
      .page-koukoujuken .content-nanq .body .thread .thread-data > div {
        display: inline-block; }
      .page-koukoujuken .content-nanq .body .thread .ymd-his {
        display: inline-block;
        margin-right: 1.6rem; }
      .page-koukoujuken .content-nanq .body .thread .label-kind {
        background: #ffdd33;
        display: inline-block;
        padding: 2px 1.6rem;
        font-size: 1.4rem; }
      .page-koukoujuken .content-nanq .body .thread .data a {
        color: #4b7bec; }
      .page-koukoujuken .content-nanq .body .thread .data .title {
        font-size: 1.6rem;
        font-weight: bold; }
      .page-koukoujuken .content-nanq .body .thread .data .desc {
        font-size: 1.4rem; }
  .page-koukoujuken .content-bbs {
    margin-bottom: 3.2rem; }
    .page-koukoujuken .content-bbs .body .thread {
      border-bottom: 1px dotted whitesmoke;
      padding: 0.8rem 0; }
      .page-koukoujuken .content-bbs .body .thread .thread-data > div {
        display: inline-block; }
      .page-koukoujuken .content-bbs .body .thread .ymd-his {
        display: inline-block;
        margin-right: 1.6rem; }
      .page-koukoujuken .content-bbs .body .thread .label-kind {
        background: #ffdd33;
        display: inline-block;
        padding: 2px 1.6rem;
        font-size: 1.4rem; }
      .page-koukoujuken .content-bbs .body .thread .data a {
        color: #4b7bec; }
      .page-koukoujuken .content-bbs .body .thread .data .title {
        font-size: 1.6rem;
        font-weight: bold; }
      .page-koukoujuken .content-bbs .body .thread .data .desc {
        font-size: 1.4rem; }
  .page-koukoujuken .content-quizzes {
    background: url("/assets/koukoujuken/index/images/quizzes.bg.png") no-repeat 0 0;
    background-size: contain;
    min-height: 200px;
    text-align: center;
    padding: 1.6rem;
    width: 100%;
    display: table;
    margin-bottom: 3.2rem; }
    .page-koukoujuken .content-quizzes .inner {
      display: table-cell;
      vertical-align: bottom; }
    .page-koukoujuken .content-quizzes .btn {
      display: inline-block;
      vertical-align: bottom;
      padding: 1.6rem 6.4rem;
      border: 2px solid white;
      color: white;
      line-height: 1; }
      .page-koukoujuken .content-quizzes .btn:hover {
        background: rgba(255, 255, 255, 0.1); }
  .page-koukoujuken .content-blog {
    border: 1px solid whitesmoke; }
    .page-koukoujuken .content-blog .title-nav {
      display: flex;
      background: #1abf9f;
      align-items: center;
      border: 1px solid white; }
      .page-koukoujuken .content-blog .title-nav .titles {
        padding: 0.8rem 1.6rem;
        color: white;
        flex-basis: 80%; }
        .page-koukoujuken .content-blog .title-nav .titles .section-title {
          font-weight: bold; }
      .page-koukoujuken .content-blog .title-nav nav {
        padding: 0.8rem 1.6rem;
        flex-basis: 20%;
        text-align: right; }
        .page-koukoujuken .content-blog .title-nav nav a {
          display: inline-block;
          color: white;
          font-size: 3rem; }
    .page-koukoujuken .content-blog .body {
      padding: 1.6rem; }
      .page-koukoujuken .content-blog .body .post-summary {
        display: flex;
        margin-bottom: 1.6rem; }
        .page-koukoujuken .content-blog .body .post-summary .label {
          background: #1abf9f;
          display: inline-block;
          color: white;
          padding: 2px 1.6rem;
          font-size: 1.4rem; }
        .page-koukoujuken .content-blog .body .post-summary .content {
          padding-left: 1.6rem; }
          .page-koukoujuken .content-blog .body .post-summary .content .title {
            font-size: 1.6rem;
            font-weight: bold; }
          .page-koukoujuken .content-blog .body .post-summary .content .desc {
            font-size: 1.4rem; }

/***************************************************************************************
 * 大学受験コミュニティ HOME
 **************************************************************************************/
.page-daigakujuken {
  /*-----------------------------------------------------------------------
   * なんきゅー
   *-----------------------------------------------------------------------*/
  /*-----------------------------------------------------------------------
   * この問題どう指導しますか
   *-----------------------------------------------------------------------*/
  /*-----------------------------------------------------------
   * 読み物
   *-----------------------------------------------------------*/ }
  .page-daigakujuken .content-nanq {
    margin-bottom: 3.2rem; }
    .page-daigakujuken .content-nanq .body .thread {
      border-bottom: 1px dotted whitesmoke;
      padding: 0.8rem 0; }
      .page-daigakujuken .content-nanq .body .thread .thread-data > div {
        display: inline-block; }
      .page-daigakujuken .content-nanq .body .thread .ymd-his {
        display: inline-block;
        margin-right: 1.6rem; }
      .page-daigakujuken .content-nanq .body .thread .label-kind {
        background: #ffdd33;
        display: inline-block;
        padding: 2px 1.6rem;
        font-size: 1.4rem; }
      .page-daigakujuken .content-nanq .body .thread .data a {
        color: #4b7bec; }
      .page-daigakujuken .content-nanq .body .thread .data .title {
        font-size: 1.6rem;
        font-weight: bold; }
      .page-daigakujuken .content-nanq .body .thread .data .desc {
        font-size: 1.4rem; }
  .page-daigakujuken .content-bbs {
    margin-bottom: 3.2rem; }
    .page-daigakujuken .content-bbs .body .thread {
      border-bottom: 1px dotted whitesmoke;
      padding: 0.8rem 0; }
      .page-daigakujuken .content-bbs .body .thread .thread-data > div {
        display: inline-block; }
      .page-daigakujuken .content-bbs .body .thread .ymd-his {
        display: inline-block;
        margin-right: 1.6rem; }
      .page-daigakujuken .content-bbs .body .thread .label-kind {
        background: #ffdd33;
        display: inline-block;
        padding: 2px 1.6rem;
        font-size: 1.4rem; }
      .page-daigakujuken .content-bbs .body .thread .data a {
        color: #4b7bec; }
      .page-daigakujuken .content-bbs .body .thread .data .title {
        font-size: 1.6rem;
        font-weight: bold; }
      .page-daigakujuken .content-bbs .body .thread .data .desc {
        font-size: 1.4rem; }
  .page-daigakujuken .content-quizzes {
    background: url("/assets/daigakujuken/index/images/quizzes.bg.png") no-repeat 0 0;
    background-size: contain;
    min-height: 200px;
    text-align: center;
    padding: 1.6rem;
    width: 100%;
    display: table;
    margin-bottom: 3.2rem; }
    .page-daigakujuken .content-quizzes .inner {
      display: table-cell;
      vertical-align: bottom; }
    .page-daigakujuken .content-quizzes .btn {
      display: inline-block;
      vertical-align: bottom;
      padding: 1.6rem 6.4rem;
      border: 2px solid white;
      color: white;
      line-height: 1; }
      .page-daigakujuken .content-quizzes .btn:hover {
        background: rgba(255, 255, 255, 0.1); }
  .page-daigakujuken .content-blog {
    border: 1px solid whitesmoke; }
    .page-daigakujuken .content-blog .title-nav {
      display: flex;
      background: #1abf9f;
      align-items: center;
      border: 1px solid white; }
      .page-daigakujuken .content-blog .title-nav .titles {
        padding: 0.8rem 1.6rem;
        color: white;
        flex-basis: 80%; }
        .page-daigakujuken .content-blog .title-nav .titles .section-title {
          font-weight: bold; }
      .page-daigakujuken .content-blog .title-nav nav {
        padding: 0.8rem 1.6rem;
        flex-basis: 20%;
        text-align: right; }
        .page-daigakujuken .content-blog .title-nav nav a {
          display: inline-block;
          color: white;
          font-size: 3rem; }
    .page-daigakujuken .content-blog .body {
      padding: 1.6rem; }
      .page-daigakujuken .content-blog .body .post-summary {
        display: flex;
        margin-bottom: 1.6rem; }
        .page-daigakujuken .content-blog .body .post-summary .label {
          background: #1abf9f;
          display: inline-block;
          color: white;
          padding: 2px 1.6rem;
          font-size: 1.4rem; }
        .page-daigakujuken .content-blog .body .post-summary .content {
          padding-left: 1.6rem; }
          .page-daigakujuken .content-blog .body .post-summary .content .title {
            font-size: 1.6rem;
            font-weight: bold; }
          .page-daigakujuken .content-blog .body .post-summary .content .desc {
            font-size: 1.4rem; }

/************************************************************************************
 * メイン画像
 ************************************************************************************/
/**************************************************************************************************
 *
 * HOME
 *
 **************************************************************************************************/
.welcome-home {
  background: url("../welcome/images/welcome.bg.jpg") no-repeat 0 0;
  background-size: cover; }
  .welcome-home .outline {
    width: 1280px;
    margin: 0 auto; }
    .welcome-home .outline .sprite {
      width: 100%;
      vertical-align: bottom; }
  .welcome-home .welcome-layout {
    display: flex;
    width: 1280px;
    margin: 0 auto; }
    .welcome-home .welcome-layout .text {
      text-align: right;
      flex-basis: 45%;
      align-self: center; }
      .welcome-home .welcome-layout .text .img-catch {
        width: 80%; }
    .welcome-home .welcome-layout .image {
      flex-basis: 55%;
      text-align: right;
      padding-top: 80px; }
      .welcome-home .welcome-layout .image .character {
        width: 100%;
        vertical-align: bottom; }
  .welcome-home h1 {
    font-size: 2.6rem;
    color: white;
    text-align: left;
    text-shadow: 0 1px 8px rgba(0, 0, 0, 0.1); }

/**************************************************************************************************
 *
 * この問題解けますか
 *
 **************************************************************************************************/
.welcome-bbs {
  background: linear-gradient(to right, #fcb045, #fd1d1d, #833ab4); }
  .welcome-bbs h1 {
    font-size: 3.8rem;
    text-align: center;
    color: white;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    padding: 9.6rem 0; }
  .welcome-bbs nav {
    text-align: center; }
    .welcome-bbs nav a {
      color: white;
      font-size: 2rem;
      display: inline-block;
      text-decoration: none;
      padding: 1.6rem 3.2rem;
      background: rgba(0, 0, 0, 0.05);
      margin-right: 4px;
      transition: all .4s; }
      .welcome-bbs nav a.active {
        background: white;
        color: black; }
      .welcome-bbs nav a:hover {
        background: rgba(255, 255, 255, 0.95);
        color: black; }

.search-bbs {
  padding: 1.6rem;
  text-align: center; }

/**************************************************************************************************
 *
 * なんきゅー
 *
 **************************************************************************************************/
.welcome-nanq {
  background: #FC466B;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #3F5EFB, #FC466B);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #3F5EFB, #FC466B);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }
  .welcome-nanq h1 {
    text-align: center;
    color: white;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    padding: 9.6rem 0; }
    .welcome-nanq h1 .juken-type {
      display: block;
      font-size: 2.6rem; }
    .welcome-nanq h1 .title {
      display: block;
      font-size: 4.6rem; }
    .welcome-nanq h1 .desc {
      display: block;
      font-size: 2rem; }
  .welcome-nanq nav {
    text-align: center; }
    .welcome-nanq nav a {
      color: white;
      font-size: 2rem;
      display: inline-block;
      text-decoration: none;
      padding: 1.6rem 3.2rem;
      background: rgba(0, 0, 0, 0.05);
      margin-right: 4px;
      transition: all .4s; }
      .welcome-nanq nav a.active {
        background: white;
        color: black; }
      .welcome-nanq nav a:hover {
        background: rgba(255, 255, 255, 0.95);
        color: black; }

.search-bbs {
  padding: 1.6rem;
  text-align: center; }

/**************************************************************************************************
 *
 * 中学受験
 *
 **************************************************************************************************/
.welcome-chugaku-home {
  padding: 12.8rem 6.4rem;
  background: linear-gradient(to right, #ffff1c, #00c3ff);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }
  .welcome-chugaku-home h1 {
    font-size: 3.4rem;
    color: white;
    text-align: center;
    text-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 3.2rem; }
  .welcome-chugaku-home p {
    text-align: center;
    color: white;
    font-size: 2rem; }

.welcome-chugaku-recruit-home {
  background: url("/assets/chugakujuken/images/recruit.home.welcome.png") no-repeat right 0;
  background-size: contain;
  padding: 0 0; }
  .welcome-chugaku-recruit-home .main-text {
    width: 54%;
    padding: 6.4rem 6.4rem;
    background: rgba(255, 221, 51, 0.9); }
    .welcome-chugaku-recruit-home .main-text h1 {
      text-align: right;
      font-size: 5.6rem;
      font-weight: bold;
      margin-bottom: 3.2rem;
      line-height: 1.3; }
      .welcome-chugaku-recruit-home .main-text h1 span {
        display: block; }
      .welcome-chugaku-recruit-home .main-text h1 .grade {
        color: #E52A2A; }
      .welcome-chugaku-recruit-home .main-text h1 .site {
        font-size: 7rem; }
    .welcome-chugaku-recruit-home .main-text p {
      text-align: right;
      font-size: 2rem; }

/**************************************************************************************************
 *
 * 高校受験
 *
 **************************************************************************************************/
.welcome-koukou-home {
  padding: 12.8rem 6.4rem;
  background: linear-gradient(to right, #F09819, #FF512F);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }
  .welcome-koukou-home h1 {
    font-size: 3.4rem;
    color: white;
    text-align: center;
    text-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 3.2rem; }
  .welcome-koukou-home p {
    text-align: center;
    color: white;
    font-size: 2rem; }

/**************************************************************************************************
 *
 * 大学受験
 *
 **************************************************************************************************/
.welcome-daigaku-home {
  padding: 12.8rem 6.4rem;
  background: linear-gradient(to right, #0072ff, #00c6ff);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }
  .welcome-daigaku-home h1 {
    font-size: 3.4rem;
    color: white;
    text-align: center;
    text-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 3.2rem; }
  .welcome-daigaku-home p {
    text-align: center;
    color: white;
    font-size: 2rem; }

/**************************************************************************************************
 *
 * 求人TOP
 *
 **************************************************************************************************/
.welcome-recruit {
  background: linear-gradient(to right, #fcb045, #fd1d1d, #833ab4); }
  .welcome-recruit h1 {
    font-size: 3.8rem;
    text-align: center;
    color: white;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    padding: 9.6rem 0; }
  .welcome-recruit nav {
    text-align: center; }
    .welcome-recruit nav a {
      color: white;
      font-size: 2rem;
      display: inline-block;
      text-decoration: none;
      padding: 1.6rem 3.2rem;
      background: rgba(0, 0, 0, 0.05);
      margin-right: 4px;
      transition: all .4s; }
      .welcome-recruit nav a.active {
        background: white;
        color: black; }
      .welcome-recruit nav a:hover {
        background: rgba(255, 255, 255, 0.95);
        color: black; }

/**************************************************************************************************
 * 検索ボックス
 **************************************************************************************************/
.search-recruit {
  background: whitesmoke;
  padding: 1.6rem; }
  .search-recruit .outline {
    width: 960px;
    margin: 0 auto; }
    .search-recruit .outline .row {
      padding: 1.6rem 0;
      border-bottom: 1px dotted #ebebeb; }
      .search-recruit .outline .row .header {
        text-align: right;
        font-weight: bold;
        padding-right: 1.6rem; }
      .search-recruit .outline .row:last-child {
        border-bottom: none; }
      .search-recruit .outline .row label {
        display: inline-block;
        margin-right: 8px; }
  .search-recruit .actions {
    text-align: center; }
    .search-recruit .actions .btn-search {
      background: #E52A2A;
      color: white;
      font-weight: bold;
      font-size: 2rem;
      display: inline-block;
      padding: 0.8rem 6.4rem;
      border-radius: 3.2rem;
      text-decoration: none; }

/**************************************************************************************************
 *
 * 求人一覧
 *
 **************************************************************************************************/
.welcome-recruit-job .outline {
  padding: 3.2rem 0;
  width: 960px;
  margin: 0 auto; }

.welcome-recruit-job .desc {
  font-weight: bold; }

.welcome-recruit-job h1 {
  font-size: 3rem;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); }

.welcome-recruit-job nav {
  text-align: center; }
  .welcome-recruit-job nav a {
    color: white;
    font-size: 2rem;
    display: inline-block;
    text-decoration: none;
    padding: 1.6rem 3.2rem;
    background: rgba(0, 0, 0, 0.05);
    margin-right: 4px;
    transition: all .4s; }
    .welcome-recruit-job nav a.active {
      background: white;
      color: black; }
    .welcome-recruit-job nav a:hover {
      background: rgba(255, 255, 255, 0.95);
      color: black; }

.welcome-recruit-job .entry {
  background: whitesmoke;
  text-align: center;
  padding: 4.8rem 0; }

.welcome-recruit-job .complete-entry {
  font-size: 3rem;
  margin-bottom: 1.6rem; }

/**************************************************************************************************
 *
 * ブログ
 *
 **************************************************************************************************/
.welcome-blog {
  min-height: 160px;
  background: linear-gradient(to right, #38ef7d, #11998e); }
  .welcome-blog .outline {
    padding: 3.2rem 0;
    width: 960px;
    margin: 0 auto; }
  .welcome-blog .in-title .title {
    font-size: 3rem;
    color: white;
    text-align: center;
    padding: 4.8rem 0; }
  .welcome-blog .in-title-category {
    padding: 4.8rem 0; }
    .welcome-blog .in-title-category .title {
      font-size: 2rem;
      color: white;
      font-weight: bold;
      text-align: center; }
    .welcome-blog .in-title-category .category {
      font-size: 3.8rem;
      color: white;
      text-align: center; }

.module {
  margin-bottom: 1.6rem; }

.module-quizzes {
  width: 100%;
  border: 4px solid whitesmoke; }
  .module-quizzes header {
    padding: 1.6rem;
    text-align: center; }
  .module-quizzes nav {
    display: flex;
    padding: 1.6rem; }
    .module-quizzes nav div {
      flex-basis: 25%;
      padding: 0 1.6rem; }
    .module-quizzes nav a {
      display: block;
      text-decoration: none;
      border: 4px solid #0652DD;
      text-align: center;
      padding: 0.8rem;
      font-weight: bold;
      color: #0652DD;
      transition: all .4s; }
      .module-quizzes nav a:hover {
        border: 4px solid #4b7bec;
        color: #4b7bec; }

.module-teach-bbs {
  width: 100%;
  border: 4px solid whitesmoke; }
  .module-teach-bbs header {
    padding: 1.6rem;
    text-align: center; }
  .module-teach-bbs nav {
    display: flex;
    padding: 1.6rem; }
    .module-teach-bbs nav div {
      flex-basis: 25%;
      padding: 0 1.6rem; }
    .module-teach-bbs nav a {
      display: block;
      text-decoration: none;
      border: 4px solid #20bf6b;
      text-align: center;
      padding: 0.8rem;
      font-weight: bold;
      color: #20bf6b;
      transition: all .4s; }
      .module-teach-bbs nav a:hover {
        border: 4px solid #26de81;
        color: #26de81; }

.module-job-news {
  width: 100%; }
  .module-job-news header {
    padding: 0.8rem;
    border-bottom: 2px solid black;
    font-size: 2rem; }
  .module-job-news .body {
    padding: 1.6rem; }
    .module-job-news .body .record {
      padding: 8px 0;
      border-bottom: 1px dotted #ccc; }
      .module-job-news .body .record p, .module-job-news .body .record h1 {
        display: inline-block; }
      .module-job-news .body .record p {
        background: black;
        color: white;
        padding: 4px 8px;
        margin-right: 0.8rem; }
      .module-job-news .body .record a {
        text-decoration: none; }

.module-job-tags {
  width: 100%; }
  .module-job-tags header {
    padding: 0.8rem;
    border-bottom: 2px solid black;
    font-size: 2rem; }
  .module-job-tags .body {
    padding: 1.6rem; }
    .module-job-tags .body a.tag {
      display: inline-block;
      padding: 4px 1.6rem;
      text-decoration: none;
      background: #fa8231;
      color: white;
      font-weight: bold;
      margin-right: 8px;
      border-radius: 2px;
      transition: all .4s; }
      .module-job-tags .body a.tag:hover {
        background: #4b7bec; }

.module-news {
  width: 100%; }
  .module-news header {
    padding: 0.8rem;
    border-bottom: 2px solid black;
    font-size: 2rem; }
  .module-news .body {
    padding: 1.6rem; }
    .module-news .body .record {
      padding: 8px 0;
      border-bottom: 1px dotted #ccc; }
      .module-news .body .record p, .module-news .body .record h1 {
        display: inline-block; }
      .module-news .body .record p {
        background: black;
        color: white;
        padding: 4px 8px;
        margin-right: 0.8rem; }
      .module-news .body .record a {
        text-decoration: none; }

.module-pickup-companies {
  width: 100%; }
  .module-pickup-companies header {
    padding: 1.6rem;
    text-align: center; }
    .module-pickup-companies header .module-title {
      font-weight: bold; }
  .module-pickup-companies .body .companies {
    display: flex; }
    .module-pickup-companies .body .companies a.company {
      text-decoration: none;
      flex-basis: 33.3333%;
      display: block;
      margin: 0.8rem;
      padding: 0.8rem;
      border: 1px solid whitesmoke;
      transition: opacity .4s; }
      .module-pickup-companies .body .companies a.company:hover {
        opacity: .8; }
      .module-pickup-companies .body .companies a.company:first-child {
        margin-right: 0; }
      .module-pickup-companies .body .companies a.company:last-child {
        margin-left: 0; }
      .module-pickup-companies .body .companies a.company .corporate-name {
        font-weight: bold;
        color: #E52A2A; }
      .module-pickup-companies .body .companies a.company .desc {
        color: #333; }

/***************************************************************
 * なんQ、掲示板の画像ポップアップ
 ***************************************************************/
#subback {
  background-color: rgba(0, 0, 0, 0.5);
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000;
  display: none; }

img.seimg {
  max-width: 200px; }

img.seimg:hover, img.sebimg:hover {
  cursor: pointer; }

img.sebimg {
  position: absolute;
  display: none;
  z-index: 1500;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 10px solid #fff; }

.job-summary {
  margin-bottom: 3.2rem; }
  .job-summary .date {
    text-align: right; }
  .job-summary .outline {
    padding: 10px 20px 15px;
    border: 1px solid #ddd;
    border-top: 3px solid #E52A2A;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12); }
  .job-summary header h1 {
    font-size: 2rem; }
    .job-summary header h1 a {
      text-decoration: none;
      color: #E52A2A;
      transition: all .4s;
      font-weight: bold; }
      .job-summary header h1 a:hover {
        color: blue; }
  .job-summary .body {
    padding: 1.6rem 0; }
    .job-summary .body .flex {
      display: flex; }
      .job-summary .body .flex .photo {
        flex-basis: 16%; }
      .job-summary .body .flex .text {
        flex: 84%;
        padding-left: 1.6rem; }

.message {
  padding: 0.8rem 1.6rem;
  margin-bottom: 1.6rem;
  border-radius: 4px; }
  .message.message-primary {
    border: 1px solid #baccf8;
    background: #dfe7fc;
    color: #4b7bec; }

.app-form header {
  padding: 1.6rem; }
  .app-form header.default {
    border-bottom: 1px solid whitesmoke; }
    .app-form header.default .title {
      font-size: 2.6rem; }

.app-form footer.default {
  padding: 1.6rem;
  border-top: 1px solid whitesmoke; }

.app-form footer.right {
  text-align: right; }

.app-form footer.center {
  text-align: center; }

.app-form .grid.grid-style-bordered .row {
  border-bottom: 1px dotted whitesmoke; }
  .app-form .grid.grid-style-bordered .row:nth-child(even) {
    background: whitesmoke; }
  .app-form .grid.grid-style-bordered .row:last-child {
    border-bottom: none; }
  .app-form .grid.grid-style-bordered .row .col {
    padding: 0.8rem 1.6rem; }

.app-form.bordered {
  border: 1px solid whitesmoke; }
  .app-form.bordered .body {
    padding: 1.6rem; }
    .app-form.bordered .body dl {
      margin-bottom: 1.6rem; }
      .app-form.bordered .body dl dt {
        font-weight: bold; }
  .app-form.bordered footer {
    padding: 1.6rem; }
    .app-form.bordered footer .actions.center {
      text-align: center; }
    .app-form.bordered footer .actions.right {
      text-align: center; }

.form-confirm.bordered {
  border: 1px solid whitesmoke; }

.form-confirm header.default {
  padding: 1.6rem;
  border-bottom: 1px solid whitesmoke; }
  .form-confirm header.default .title {
    font-size: 2.6rem; }

.form-confirm .body {
  padding: 1.6rem; }
  .form-confirm .body p {
    margin-bottom: 1.6rem; }
  .form-confirm .body dl {
    margin-bottom: 1.6rem; }
    .form-confirm .body dl dt {
      font-weight: bold; }

.form-confirm footer {
  padding: 1.6rem; }
  .form-confirm footer .submit {
    display: inline-block; }
  .form-confirm footer.default {
    padding: 1.6rem;
    border-top: 1px solid whitesmoke; }
  .form-confirm footer .actions.center {
    text-align: center; }
  .form-confirm footer .actions.right {
    text-align: right; }
