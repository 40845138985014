
.page-nanq-search {
  .thread-summary {
    margin-bottom: $unit;
    .label-value {
      @include fs(-1);
    }

    .title {
      @include fs(1);
      font-weight: bold;
    }
  }
}