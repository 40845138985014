.page-bbs-thread {
    .btn-good {
        text-decoration: none;
    }

  /*-----------------------------------------------------
   * 質問
   *-----------------------------------------------------*/
    .question {
        border: 1px solid #ffdd33;
        margin-bottom: $unit*2;
        background: rgba(#ffdd33, .1);
        header {
            padding: $unit;

            .thread-date {
                > div {
                    display: inline-block;

                    &.category {
                        margin-left: $unit;
                    }
                }

                .label {
                    font-weight: bold;
                }
            }
            .question-title {
                @include fs(2);
                font-weight: bold;
            }
        }

        .post-user {
            padding: $unit/2 $unit;
            display: flex;
            width: 100%;
            align-items: center;
            border-top: 1px solid rgba(#ffdd33, .5);
            background: white;
            .photo {
                flex-basis: 8%;
                img {
                    width: 100%;
                }
            }

        .user {
            flex-basis: 40%;
            padding-left: $unit;
        }

        .sns-buttons {
            flex-basis: 52%;
            text-align: right;
        }
    }

    .detail-text {
        padding: $unit;
        .flex {
            display: flex;
            .photo {
                flex-basis: 24%;
                img {
                    width: 100%;
                }
            }
            .text {
                flex-basis: 76%;
                padding-left: $unit;
            }
        }
    }

    .actions {
        padding: $unit;
        text-align: center;
    }

}

/*-----------------------------------------------------
 * ベストアンサー
 *-----------------------------------------------------*/
.best-answer {
    margin-bottom: $unit;
    > header {
        .title {
            text-align: center;
            @include fs(2);
        }
    }

    article {
        background: #eef3fb;
        border: 1px solid #6699ff;
        header {
            padding: $unit;
            .title {
                @include fs(2);
            }
        }

        .body {
            padding: $unit;
        }

        footer {
            padding: $unit/2 $unit;
            .grid {
                margin-bottom: 0;
                .row {
                    align-items: center;
                }
            }
        }
    }

    .text-box {
        padding: $unit;
        .flex {
            display: flex;
            .photo {
                flex-basis: 24%;
                img {
                    width: 100%;
                }
            }
            .text {
                flex-basis: 76%;
                padding-left: $unit;
            }
        }
    }
}

/*-----------------------------------------------------
 * 解答
 *-----------------------------------------------------*/
.answer {
    margin-bottom: $unit;
    border: 1px solid whitesmoke;

    header {
        padding: $unit;
        .title {
            @include fs(3);
        }
    }

    .text-box {
        padding: $unit;
        .flex {
            display: flex;
            .photo {
                flex-basis: 16%;
            }
            .text {
                padding-left: $unit;
                flex-basis: 84%;
            }
        }
    }

    .answer-user {
        display: flex;
        .photo {
            flex-basis: 8%;
        }

        .profile {
            padding-left: $unit;
            flex-basis: 92%;
            span {
                display: block
            }
        }
    }

    footer {
        padding: $unit/2 $unit;
        .grid {
            margin: 0;
        }
        .btn-best-answer {
            padding: $unit/2 $unit*3;
            background: #e84838;
            color: white;
            border: none;
            border-radius: 4px;
            &:hover {
                cursor: pointer;
            }
        }

        .row {
            align-items: center;
        }
    }
}
}
