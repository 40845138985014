/**************************************************************************************************
 *
 * HOME
 *
 **************************************************************************************************/
.welcome-home {
  //padding: $unit*10 $unit*4;
  //background: linear-gradient(to right, #ffc3a0, #FFAFBD);
  //background: linear-gradient(to right, #ffff1c, #00c3ff);
  background: url("../welcome/images/welcome.bg.jpg") no-repeat 0 0;
  background-size: cover;

  .outline {
    width: 1280px;
    margin: 0 auto;
    .sprite {
      width: 100%;
      vertical-align: bottom;
    }
  }
  .welcome-layout {
    display: flex;
    width: 1280px;
    margin: 0 auto;

    .text {
      text-align: right;
      flex-basis: 45%;
      align-self: center;
      .img-catch {
        width: 80%;
      }
    }

    .image {
      flex-basis: 55%;
      text-align: right;
      padding-top: 80px;
      .character {
        width: 100%;
        vertical-align: bottom;
      }
    }
  }

  h1 {
    @include fs(2);
    color: white;
    text-align: left;
    text-shadow: 0 1px 8px rgba(black, .1);
  }
}
/**************************************************************************************************
 *
 * この問題解けますか
 *
 **************************************************************************************************/
.welcome-bbs {
  background: linear-gradient(to right, #fcb045, #fd1d1d, #833ab4);

  h1 {
    @include fs(5);
    text-align: center;
    color: white;
    text-shadow: 0 1px 4px rgba(black, .1);
    padding: $unit*6 0;
  }

  nav {
    text-align: center;
    a {
      color: white;
      @include fs(1);
      display: inline-block;
      text-decoration: none;
      padding: $unit $unit*2;
      background: rgba(black, .05);
      margin-right: 4px;
      transition: all .4s;

      &.active {
        background: white;
        color: black;
      }

      &:hover {
        background: rgba(white, .95);
        color: black;
      }
    }
  }

}

.search-bbs {
  padding: $unit;
  text-align: center;
}

/**************************************************************************************************
 *
 * なんきゅー
 *
 **************************************************************************************************/
.welcome-nanq {
  background: #FC466B;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #3F5EFB, #FC466B);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #3F5EFB, #FC466B); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  h1 {
    text-align: center;
    color: white;
    text-shadow: 0 1px 4px rgba(black, .1);
    padding: $unit*6 0;
    .juken-type {
      display: block;
      @include fs(2);
    }
    .title {
      display: block;
      @include fs(7);
    }
    .desc {
      display: block;
      @include fs(1);
    }
  }

  nav {
    text-align: center;
    a {
      color: white;
      @include fs(1);
      display: inline-block;
      text-decoration: none;
      padding: $unit $unit*2;
      background: rgba(black, .05);
      margin-right: 4px;
      transition: all .4s;

      &.active {
        background: white;
        color: black;
      }

      &:hover {
        background: rgba(white, .95);
        color: black;
      }
    }
  }

}

.search-bbs {
  padding: $unit;
  text-align: center;
}

/**************************************************************************************************
 *
 * 中学受験
 *
 **************************************************************************************************/
.welcome-chugaku-home {
  padding: $unit*8 $unit*4;
  //background: linear-gradient(to right, #ffc3a0, #FFAFBD);
  background: linear-gradient(to right, #ffff1c, #00c3ff); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  h1 {
    @include fs(4);
    color: white;
    text-align: center;
    text-shadow: 0 1px 8px rgba(black, .1);
    margin-bottom: $unit*2;
  }

  p {
    text-align: center;
    color: white;
    @include fs(1);
  }
}

.welcome-chugaku-recruit-home {
  background: url("/assets/chugakujuken/images/recruit.home.welcome.png") no-repeat right 0;
  background-size: contain;
  padding: 0 0;
  .main-text {
    width: 54%;
    padding: $unit*4 $unit*4;
    //background: linear-gradient(to right, #ffff1c, #00c3ff);
    background: rgba(255, 221, 51, .9);
    h1 {
      text-align: right;
      font-size: 5.6rem;
      font-weight: bold;
      //text-shadow: 0 1px 8px rgba(white, .9);
      margin-bottom: $unit*2;
      line-height: 1.3;
      span {
        display: block;
      }
      .grade {
        color: #E52A2A;
      }

      .site {
        font-size: 7rem;
      }
    }

    p {
      text-align: right;
      @include fs(1);
    }
  }

}


/**************************************************************************************************
 *
 * 高校受験
 *
 **************************************************************************************************/

.welcome-koukou-home {
  padding: $unit*8 $unit*4;
  background: linear-gradient(to right, #F09819, #FF512F); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


  h1 {
    @include fs(4);
    color: white;
    text-align: center;
    text-shadow: 0 1px 8px rgba(black, .1);
    margin-bottom: $unit*2;
  }

  p {
    text-align: center;
    color: white;
    @include fs(1);
  }
}

/**************************************************************************************************
 *
 * 大学受験
 *
 **************************************************************************************************/
.welcome-daigaku-home {
  padding: $unit*8 $unit*4;
  //background: linear-gradient(to right, #ffc3a0, #FFAFBD);
  background: linear-gradient(to right, #0072ff, #00c6ff); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  h1 {
    @include fs(4);
    color: white;
    text-align: center;
    text-shadow: 0 1px 8px rgba(black, .1);
    margin-bottom: $unit*2;
  }

  p {
    text-align: center;
    color: white;
    @include fs(1);
  }
}




/**************************************************************************************************
 *
 * 求人TOP
 *
 **************************************************************************************************/
.welcome-recruit {
  background: linear-gradient(to right, #fcb045, #fd1d1d, #833ab4);

  h1 {
    @include fs(5);
    text-align: center;
    color: white;
    text-shadow: 0 1px 4px rgba(black, .1);
    padding: $unit*6 0;
  }

  nav {
    text-align: center;
    a {
      color: white;
      @include fs(1);
      display: inline-block;
      text-decoration: none;
      padding: $unit $unit*2;
      background: rgba(black, .05);
      margin-right: 4px;
      transition: all .4s;

      &.active {
        background: white;
        color: black;
      }

      &:hover {
        background: rgba(white, .95);
        color: black;
      }
    }
  }

}

/**************************************************************************************************
 * 検索ボックス
 **************************************************************************************************/
.search-recruit {
  background: whitesmoke;
  padding: $unit;
  .outline {
    width: 960px;
    margin: 0 auto;
    .row {
      padding: $unit 0;
      border-bottom: 1px dotted darken(whiteSmoke, 4%);
      .header {
        text-align: right;
        font-weight: bold;
        padding-right: $unit;
      }
      &:last-child {
        border-bottom: none;
      }
      label {
        display: inline-block;
        margin-right: 8px;
      }
    }
  }

  .actions {
    text-align: center;

    .btn-search {
      background: #E52A2A;
      color: white;
      font-weight: bold;
      @include fs(1);
      display: inline-block;
      padding: $unit/2 $unit*4;
      border-radius: $unit*2;
      text-decoration: none;
    }
  }


}


/**************************************************************************************************
 *
 * 求人一覧
 *
 **************************************************************************************************/

.welcome-recruit-job {
  //background: linear-gradient(to right, #fcb045, #fd1d1d, #833ab4);
  .outline {
    padding: $unit*2 0;
    width: 960px;
    margin: 0 auto;
  }

  .desc {
    font-weight: bold;
  }

  h1 {
    @include fs(3);
    text-shadow: 0 1px 4px rgba(black, .1);
  }

  .tags {

  }

  nav {
    text-align: center;
    a {
      color: white;
      @include fs(1);
      display: inline-block;
      text-decoration: none;
      padding: $unit $unit*2;
      background: rgba(black, .05);
      margin-right: 4px;
      transition: all .4s;

      &.active {
        background: white;
        color: black;
      }

      &:hover {
        background: rgba(white, .95);
        color: black;
      }
    }
  }


  .entry {
    background: whitesmoke;
    text-align: center;
    padding: $unit*3 0;
  }


  .complete-entry {
    @include fs(3);
    margin-bottom: $unit;
  }


}


/**************************************************************************************************
 *
 * ブログ
 *
 **************************************************************************************************/
.welcome-blog {

  min-height: 160px;
  .outline {
    padding: $unit*2 0;
    width: 960px;
    margin: 0 auto;
  }

  .in-title {
    .title {
      @include fs(3);
      color: white;
      text-align: center;
      padding: $unit*3 0;
    }
  }

  .in-title-category {
    padding: $unit*3 0;

    .title {
      @include fs(1);
      color: white;
      font-weight: bold;
      text-align: center;
    }

    .category {
      @include fs(5);
      color: white;
      text-align: center;

    }
  }

  background: linear-gradient(to right, #38ef7d, #11998e);

}