.page-nanq-thread {

  /*-----------------------------------------------------
   * 質問
   *-----------------------------------------------------*/
  .question {
    border: 1px solid #ffdd33;
    margin-bottom: $unit*2;
    background: rgba(#ffdd33, .1);
    header {
      padding: $unit;

      .thread-date {
        > div {
          display: inline-block;

          &.category {
            margin-left: $unit;
          }
        }

        .label {
          font-weight: bold;
        }
      }
      .question-title {
        @include fs(2);
        font-weight: bold;
      }
    }

    .post-user {
      padding: $unit/2 $unit;
      display: flex;
      width: 100%;
      align-items: center;
      border-top: 1px solid whitesmoke;
      background: whitesmoke;
      .photo {
        flex-basis: 8%;
        img {
          width: 100%;
        }
      }

      .user {
        flex-basis: 40%;
        padding-left: $unit;
      }

      .sns-buttons {
        flex-basis: 52%;
        text-align: right;
      }
    }

    .detail-text {
      padding: $unit;
      .flex {
        display: flex;
        .photo {
          flex-basis: 24%;
          img {
            width: 100%;
          }
        }
        .text {
          flex-basis: 76%;
          padding-left: $unit;
        }
      }
    }

    .actions {
      padding: $unit;
      text-align: center;
    }

  }


  /*-----------------------------------------------------
   * 解答
   *-----------------------------------------------------*/

  .answers {
    padding: $unit;
  }

  .answer {
    margin-bottom: $unit;

    border: 1px solid whitesmoke;
    border-radius: 4px;

    header {
      padding: $unit/2 $unit;
      .answer-date {
        display: flex;
        .date {
          flex-basis: 50%;
          @include fs(-1);
        }

        .related {
          flex-basis: 50%;
          text-align: right;
          @include fs(-1);
          a {
            text-decoration: none;
            color: #4b7bec;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .answer-title {
        @include fs(1);
        font-weight: 800;
      }
    }


    .answer-user {
      display: flex;
      .photo {
        flex-basis: 8%;
      }

      .profile {
        padding-left: $unit;
        flex-basis: 92%;
        span {
          display: block;
          &.subject {
            @include fs(2);
          }
        }
      }
    }

    .text-box {
      padding: $unit;
      .flex {
        display: flex;
        .photo {
          flex-basis: 16%;
        }
        .text {
          padding-left: $unit;
          flex-basis: 84%;
        }
      }
    }

    footer {
      display: flex;
      align-items: center;
      padding: $unit/2 $unit;
      .answer-user {
        flex-basis: 50%;
        .photo {
          img {
            vertical-align: middle;
          }
        }
        .name {
          display: inline-block;
          margin-left: $unit/2;
          padding: $unit/2 0;

        }
      }

      .actions {
        text-align: right;
        flex-basis: 50%;
        a {
          text-decoration: none;
          margin-left: $unit;
        }
      }
    }
  }
}