.module-pickup-companies {

  width: 100%;

  header {
    padding: $unit;
    text-align: center;
    .module-title {
      font-weight: bold;
    }
  }

  .body {
    .companies {
      display: flex;
      a.company {
        text-decoration: none;
        flex-basis: 33.3333%;
        display: block;
        margin: $unit/2;
        padding: $unit/2;
        border: 1px solid whitesmoke;

        transition: opacity .4s;
        &:hover {
          opacity: .8;
        }
        &:first-child {
          margin-right: 0;
        }
        &:last-child {
          margin-left: 0;
        }

        .corporate-name {
          font-weight: bold;
          color: #E52A2A;
        }

        .desc {
          color: #333;
        }
      }
    }

  }

}