.job-summary {
  margin-bottom: $unit*2;

  .date {
    text-align: right;
  }

  .outline {
    padding: 10px 20px 15px;
    border: 1px solid #ddd;
    border-top: 3px solid #E52A2A;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
  }
  header {
    .desc {
    }

    h1 {
      @include fs(1);
      a {
        text-decoration: none;
        color: #E52A2A;
        transition: all .4s;
        font-weight: bold;
        &:hover {
          color: blue;
        }
      }
    }
  }

  .body {
    padding: $unit 0;
    .flex {
      display: flex;
      .photo {
        flex-basis: 16%;
      }

      .text {
        flex: 84%;
        padding-left: $unit;
      }
    }
  }
}