.module-news {
  width: 100%;

  header {
    padding: $unit/2;
    border-bottom: 2px solid black;
    @include fs(1);
  }

  .body {
    padding: $unit;
    .record {
      padding: 8px 0;
      border-bottom: 1px dotted #ccc;
      p, h1 {
        display: inline-block;
      }

      p {
        background: black;
        color: white;
        padding: 4px 8px;
        margin-right: $unit/2;
      }

      a {
        text-decoration: none;
      }
    }
  }


}